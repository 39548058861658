import React, { Component } from 'react';
import { Card, Button, message } from 'antd';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import Configuration from '../Configuration';
import Logo from '../images/logo.png';
import './Login.css';

class Login extends Component {
  constructor() {
    super();

    this.state = {username: '', password: '', redirect: false, redirectUrl: '/'};
    this.handleUser = this.handleUser.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleUser(event) {
    this.setState({username: event.target.value});
  }

  handlePassword(event) {
    this.setState({password: event.target.value});
  }

  handleCompany(event) {
    this.setState({subdomain: event.target.value});
  }

  handleLogin() {
    if(this.validate(this.state)) {
      const sendData = {user: {username: this.state.username, password_hash: this.state.password}};

      // axios.post(`${Configuration.apiServer}/api/v1/login`, sendData).then(resp => {
      //   if(resp.status !== 200) {
      //     return message.warning('Hubo un error al procesar tus credenciales');
      //   }
        
      //   if(resp.data.status === 'error') {
      //     return message.warning('Usuario o contraseña invalida');
      //   }

      //   // Save localstorage
      //   // localStorage.setItem('token', resp.data.token);
      //   // localStorage.setItem('id', resp.data.user.profile.id);
      //   // localStorage.setItem('names', resp.data.user.profile.names);
      //   // localStorage.setItem('branch_id', resp.data.user.profile.branch.id);
      //   // localStorage.setItem('branch_name', resp.data.user.profile.branch.name);
      //   // localStorage.setItem('company', resp.data.user.profile.company);
      //   // localStorage.setItem('rol', resp.data.user.profile.rol.name);

      //   // Update redirection props
      //   const redirectTo = Configuration.redirectUrl(resp.data.user.profile.rol.name);
      //   this.setState({redirectUrl: redirectTo, redirect: true});
      // });

      if (this.state.username == 'admin' && this.state.password == '12345') {
        localStorage.setItem('rol', 'admin');
        this.setState({redirectUrl: '/organos-acreditadores', redirect: true});
      } else if (this.state.username == 'coordinador' && this.state.password == '12345') {
        localStorage.setItem('rol', 'coordinador');
        this.setState({redirectUrl: '/organos-acreditadores', redirect: true});
      } else if (this.state.username == 'profesor' && this.state.password == '12345') {
        localStorage.setItem('rol', 'profesor');
        this.setState({redirectUrl: '/organos-acreditadores', redirect: true});
      } else {
        message.warning('Usuario y contraseña incorrectas');
      }
    } else {
      message.warning('Todos los campos son obligatorios');
    }
  }

  validate(fields) {
    let pass = true;

    for(let field in fields) {
      if(field !== undefined && fields[field].length < 1) {
        pass = false;
      }
    }

    return pass;
  }

  render() {
    // Login redirect
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    // Render component
    return(
      <div style={{ background: '#f1f1f1', height: '100vh' }}>
        <div className="container-center" style={{ height: '90vh' }}>
          
          <Card className="login-content shadow border-radius-md" style={{borderTop: '2px solid #1C91FF'}}>            
            <div className='container-center container-column' style={{ marginTop: '60px' }}>
              <h3 style={{textAlign: 'center'}}>Recuperar contraseña</h3>

              <div className="form-group">
                <h4>Escribe tu email o usuario</h4>
                <input type="text" className="form-control" placeholder="" onChange={this.handlePassword} />
              </div>
            </div>
            
            <div className="container-center container-column" style={{ margin: '20px 0px' }}>
              <Button type="primary" onClick={this.handleLogin}>Recuperar</Button>
              <Button type="dashed" style={{ marginTop: '10px' }}><a href="/login">Volver</a></Button>
            </div>
          </Card>

        </div>
      </div>
    );
  }
}

export default Login;
