import { DeleteOutlined, EditOutlined, SearchOutlined, PlusOutlined, BellOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Modal, Form, Popconfirm, Tag, List, Avatar } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/dcea_logo.png';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';

const { Meta } = Card;
const { Search } = Input;
const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();

class Perfil extends Component {
  constructor(props) {
    super(props);

    const records = [
      {title: 'Justificar indicador 7.1.2', description: 'Justificación...', user: {name: 'Juan Perez', rol: 'Profesor'}},
      {title: 'Comentar indicador 7.1.2', description: 'Comentario....', user: {name: 'Carla Perez', rol: 'Coordinador'}},
      {title: 'Subir evidencia 7.1.2', description: 'Nombre del archivo...', user: {name: 'Juan Perez', rol: 'Profesor'}},
      {title: 'Autorizar indicador 7.1.2', description: 'Indicador autorizado', user: {name: 'Francisco Perez', rol: 'Auxiliar'}},
    ];
    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: records, records: records, loading: false, openModal: false, modalTitle: '', selectedRecord: {}};
  }

  onSearch(words) {
    this.setState({searching: true});

    // Search record
    const list = this.state.allRecords.filter((el) => {
      if (el.title.match(words)) {
        return el;
      }
    });
    
    // Update list 
    this.setState({records: list, searching: false});
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    if (item.key != "2") {
      const route = Configuration.getRoute(item);
      this.setState({redirect: true, redirectUrl: route});
    }
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo área'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = newRecords.length + 1;
    record.users = 0;
    record.indicators = 0;
    newRecords.push(record);
    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar área', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // Find record
    const list = newRecords.map(el => { 
      if (el.id == selected.id) {
        let newRecord = {...el};
        newRecord.name = record.name;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: list});
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    const newRecords = this.state.records;
    delete newRecords[filter];
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  openDetails(record) {
    this.setState({redirect: true, redirectUrl: `/area/${record.id}`});
  }



  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Top menu */}
        <TopMenu />

        {/* Content with two columns */}
        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div style={{padding: 24, textAlign: 'center'}}>

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px' }}>
                <h3>Mi pefil </h3>

                <div className='container-end' style={{width: '50%'}}>
                  <Search placeholder="Buscar..." onSearch={this.onSearch.bind(this)} style={{ width: '70%' }} enterButton="Buscar" size="large" loading={this.state.searching} />
                </div>
              </div>

              {/* Content */}
              <div className="container-around">
                <List
                  itemLayout="horizontal"
                  style={{width: '100%'}}
                  dataSource={this.state.records}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                        title={<a href="#">{item.title}</a>}
                        description={
                          <div>
                            <p style={{marginBottom: 0}}>{item.description}</p>
                            <p style={{marginBottom: 0, fontWeight: 'bold'}}>{item.user?.rol} {item.user?.name}</p>
                          </div>
                        }
                      />
                      <div>
                        <Tag color="gold">Pendiente</Tag>
                        <Button type="primary">Ver</Button>
                      </div>
                    </List.Item>
                  )}
                />
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Perfil;
