import { LikeOutlined, BellOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Progress, DatePicker, Tree } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/dcea_logo.png';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';

const { DirectoryTree } = Tree;
const { RangePicker } = DatePicker;
const { Meta } = Card;
const { Search } = Input;
const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();

class Limpiar extends Component {
  constructor(props) {
    super(props);

    const records = [
      {
        title: 'Finanzas',
        key: '0-0',
        children: [
          {
            title: 'Categoría 1',
            key: '0-0-0',
            children: [
              {
                title: 'Criterio 2',
                key: '0-0-0-1',
                children: [
                  {
                    title: 'Evidencia 1',
                    key: '0-0-0-1-0',
                    isLeaf: true,
                  },
                  {
                    title: 'Evidencia 2',
                    key: '0-0-0-1-1',
                    isLeaf: true,
                  },
                ]
              },
              {
                title: 'Criterio 4',
                key: '0-0-0-2',
              },
            ]
          },
          {
            title: 'Categoría 2',
            key: '0-0-1',
            children: [
              {
                title: 'Criterio 1',
                key: '0-0-1-1',
              },
            ]
          },
        ],
      },
      {
        title: 'Ciencias',
        key: '0-1',
        children: [
          {
            title: 'Evidencia 1',
            key: '0-1-0',
            isLeaf: true,
          },
          {
            title: 'Evenidencia 2',
            key: '0-1-1',
            isLeaf: true,
          },
        ],
      },
    ];
    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: records, records: records, loading: false, openModal: false, modalTitle: '', selectedRecord: {}};
  }

  onSearch(words) {
    this.setState({searching: true});

    // Search record
    const list = this.state.allRecords.filter((el) => {
      if (el.title.match(words)) {
        return el;
      }
    });
    
    // Update list 
    this.setState({records: list, searching: false});
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    if (item.key != "2") {
      const route = Configuration.getRoute(item);
      this.setState({redirect: true, redirectUrl: route});
    }
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo área'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = newRecords.length + 1;
    record.users = 0;
    record.indicators = 0;
    newRecords.push(record);
    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar área', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // Find record
    const list = newRecords.map(el => { 
      if (el.id == selected.id) {
        let newRecord = {...el};
        newRecord.name = record.name;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: list});
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    const newRecords = this.state.records;
    delete newRecords[filter];
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  openDetails(record) {
    this.setState({redirect: true, redirectUrl: `/area/${record.id}`});
  }



  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout hasSider>
        {/* Main layout and side menu */}
        <Sider style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, background: '#fff'}}>
          <div className="container-center" style={{ paddingBottom: '10px', flexWrap: 'wrap', flexDirection: 'column'}}>
            <img src={Logo} height="44" alt="DCEA" style={{ margin: '20px 0px 0px 0px' }} />
            <h3 style={{textAling: 'center', color: '#233C60', fontSize: '0.6em'}}>Sistema de Evaluación y Acreditación</h3>
          </div>

          <Menu theme="light" mode="inline" defaultSelectedKeys={['5']} items={items} style={{marginTop: 20}} onClick={this.onClickSideMenu.bind(this)} />
        </Sider>

        <Layout className="site-layout" style={{marginLeft: 200}}>
          {/* Top menu */}
          <TopMenu />

          <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px' }}>
                <h3>Limpiar espacio</h3>
              </div>

              {/* Content */}
              <div className="container-around">
                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%'}}>
                  <h3>Limpiar por archivos</h3>

                  <DirectoryTree
                    multiple
                    onSelect={() => { console.log('select') }}
                    onExpand={() => { }}
                    treeData={this.state.records}
                  />
                </div>

                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%'}}>
                  <h3>Limpiar por fecha</h3>
                  
                  <RangePicker />

                  <div style={{textAlign: 'center', marginTop: 20}}>
                    <Button type="primary"><a href="/limpiar">Limpiar</a></Button>
                  </div>
                </div>
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Limpiar;
