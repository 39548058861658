import { LikeOutlined, BellOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Progress, Statistic } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/dcea_logo.png';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';

const { Meta } = Card;
const { Search } = Input;
const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();

class Servidor extends Component {
  constructor(props) {
    super(props);

    const records = [
      {title: 'Justificar indicador 7.1.2', description: 'Justificación...', user: {name: 'Juan Perez', rol: 'Profesor'}},
      {title: 'Comentar indicador 7.1.2', description: 'Comentario....', user: {name: 'Carla Perez', rol: 'Coordinador'}},
      {title: 'Subir evidencia 7.1.2', description: 'Nombre del archivo...', user: {name: 'Juan Perez', rol: 'Profesor'}},
      {title: 'Autorizar indicador 7.1.2', description: 'Indicador autorizado', user: {name: 'Francisco Perez', rol: 'Auxiliar'}},
    ];
    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: records, records: records, loading: false, openModal: false, modalTitle: '', selectedRecord: {}};
  }

  onSearch(words) {
    this.setState({searching: true});

    // Search record
    const list = this.state.allRecords.filter((el) => {
      if (el.title.match(words)) {
        return el;
      }
    });
    
    // Update list 
    this.setState({records: list, searching: false});
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    if (item.key != "2") {
      const route = Configuration.getRoute(item);
      this.setState({redirect: true, redirectUrl: route});
    }
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo área'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = newRecords.length + 1;
    record.users = 0;
    record.indicators = 0;
    newRecords.push(record);
    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar área', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // Find record
    const list = newRecords.map(el => { 
      if (el.id == selected.id) {
        let newRecord = {...el};
        newRecord.name = record.name;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: list});
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    const newRecords = this.state.records;
    delete newRecords[filter];
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  openDetails(record) {
    this.setState({redirect: true, redirectUrl: `/area/${record.id}`});
  }



  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout hasSider>
        {/* Main layout and side menu */}
        <Sider style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, background: '#fff'}}>
          <div className="container-center" style={{ paddingBottom: '10px', flexWrap: 'wrap', flexDirection: 'column'}}>
            <img src={Logo} height="44" alt="DCEA" style={{ margin: '20px 0px 0px 0px' }} />
            <h3 style={{textAling: 'center', color: '#233C60', fontSize: '0.6em'}}>Sistema de Evaluación y Acreditación</h3>
          </div>

          <Menu theme="light" mode="inline" defaultSelectedKeys={['5']} items={items} style={{marginTop: 20}} onClick={this.onClickSideMenu.bind(this)} />
        </Sider>

        <Layout className="site-layout" style={{marginLeft: 200}}>
          {/* Top menu */}
          <TopMenu />

          <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px' }}>
                <h3>Status de servidor</h3>
              </div>

              {/* Content */}
              <div className="container-around">
                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%'}}>
                  <h3>Disco duro</h3>
                  <Progress strokeLinecap="butt" percent={75} />
                  <br />
                </div>

                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%'}}>
                  <h3>Espacio usado por evidencia</h3>
                  <Progress strokeLinecap="butt" percent={55} />
                  <div style={{textAlign: 'center'}}>
                    <Button type="primary"><a href="/limpiar">Limpiar</a></Button>
                  </div>
                </div>
              

                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%', marginTop: 40}}>
                  <h3>Uso de RAM</h3>
                  <Progress strokeLinecap="butt" type="circle" percent={22} />
                </div>

                <div style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '45%', marginTop: 40}}>
                  <h3 style={{marginBottom: 20}}>Procesos y aplicaciones</h3>
                  
                  <div className='container-between' style={{borderBottom: '1px solid #ddd', marginBottom: 10, paddingBottom: 10}}>
                    <Statistic value={'API'} />
                    <CheckCircleOutlined style={{color: 'green'}} />
                  </div>

                  <div className='container-between' style={{borderBottom: '1px solid #ddd', marginBottom: 10, paddingBottom: 10}}>
                    <div>
                      <Statistic value={'Interfaz'} />
                      <Button type="primary">Arrancar</Button>
                    </div>
                    <CloseCircleOutlined style={{color: 'red'}} />
                  </div>

                  <div className='container-between' style={{borderBottom: '1px solid #ddd', marginBottom: 10, paddingBottom: 10}}>
                    <Statistic value={'Base de Datos'} />
                    <CheckCircleOutlined style={{color: 'green'}} />
                  </div>

                  <div className='container-between' style={{borderBottom: '1px solid #ddd', marginBottom: 10, paddingBottom: 10}}>
                    <Statistic value={'Mailing'} />
                    <CheckCircleOutlined style={{color: 'green'}} />
                  </div>
                </div>
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Servidor;
