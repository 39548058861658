import { PlusOutlined, ArrowRightOutlined, FileSearchOutlined, DeleteOutlined, EditOutlined, FileTextOutlined, SelectOutlined, UploadOutlined, FileOutlined, WechatOutlined } from '@ant-design/icons';
import { Layout, Input, Select, Button, Modal, Breadcrumb, List, Row, Col, Tooltip, message, Popconfirm, Upload, Skeleton } from 'antd';
import React, { Component } from 'react';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const {Content} = Layout;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class OrganoDetalles extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    // State
    this.state = {
      searching: false, 
      redirect: false, 
      redirectUrl: '', 
      records: [], 
      carrers: [],
      users: [],
      acreditorUsers: [],
      loading: false, 
      openModal: false, 
      modalTitle: '', 
      selectedRecord: {}, 
      openModalInstrument: false,
      openModalQuestion: false, 
      modalTitleQuestion: '', 
      selectedQuestion: {}, 
      acreditor: {},
      openModalCarrers: false,
      openDetailsModal: false,
      conaetOfficialRecordInstitutional: '',
      conaetOfficialRecordInstitutionalResponse: '',
      conaetEducativeModel: '',
      conaetEducativeModelResponse: '',
      conaetAgeEducationalProgram: '',
      conaetAgeEducationalProgramResponse: '',
      conaetRelevanceStudy: '',
      conaetRelevanceStudyResponse: '',
      conaetGraduateGenerations: '',
      conaetGraduateGenerationsResponse: '',
      conaetStatisticsData: '',
      conaetStatisticsDataResponse: '',
      conaetOfficialRecordInstitutionalTmp: [],
      conaetOfficialRecordInstitutionalFiles: [],
      conaetEducativeModelTmp: [],
      conaetEducativeModelFiles: [],
      conaetAgeEducationalProgramTmp: [],
      conaetAgeEducationalProgramFiles: [],
      conaetRelevanceStudyTmp: [],
      conaetRelevanceStudyFiles: [],
      conaetGraduateGenerationsTmp: [],
      conaetGraduateGenerationsFiles: [],
      conaetStatisticsDataTmp: [],
      conaetStatisticsDataFiles: [],
      prevReqTab: 'registroOficial'
    };
  }

  componentDidMount() {
    const params = this.props.params;
    if ('id' in params) {
      this.getAcreditor(params.id);
      this.getRecords(params.id);
      this.getUsers("");
    }
  }

  getAcreditor(id) {
    const query = `{getAcreditor(id: ${id}){id,shortName,conaetOfficialRecordInstitutional,conaetOfficialRecordInstitutionalResponse,conaetEducativeModel,conaetEducativeModelResponse,conaetAgeEducationalProgram,conaetAgeEducationalProgramResponse,conaetRelevanceStudy,conaetRelevanceStudyResponse,conaetGraduateGenerations,conaetGraduateGenerationsResponse,conaetStatisticsData,conaetStatisticsDataResponse}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al consultar el órgano acreditador.');
      }

      if (resp.data.data.getAcreditor?.shortName?.toLowerCase() === 'conaet') {
        this.setState({
          acreditor: resp.data.data.getAcreditor, 
          conaetOfficialRecordInstitutional: resp.data.data.getAcreditor?.conaetOfficialRecordInstitutional, 
          conaetOfficialRecordInstitutionalResponse: resp.data.data.getAcreditor?.conaetOfficialRecordInstitutionalResponse, 
          conaetEducativeModel: resp.data.data.getAcreditor?.conaetEducativeModel, 
          conaetEducativeModelResponse: resp.data.data.getAcreditor?.conaetEducativeModelResponse, 
          conaetAgeEducationalProgram: resp.data.data.getAcreditor?.conaetAgeEducationalProgram, 
          conaetAgeEducationalProgramResponse: resp.data.data.getAcreditor?.conaetAgeEducationalProgramResponse, 
          conaetRelevanceStudy: resp.data.data.getAcreditor?.conaetRelevanceStudy, 
          conaetRelevanceStudyResponse: resp.data.data.getAcreditor?.conaetRelevanceStudyResponse, 
          conaetGraduateGenerations: resp.data.data.getAcreditor?.conaetGraduateGenerations, 
          conaetGraduateGenerationsResponse: resp.data.data.getAcreditor?.conaetGraduateGenerationsResponse, 
          conaetStatisticsData: resp.data.data.getAcreditor?.conaetStatisticsData,
          conaetStatisticsDataResponse: resp.data.data.getAcreditor?.conaetStatisticsDataResponse
        });
      } else {
        this.setState({acreditor: resp.data.data.getAcreditor});
      }
    });
  }

  getRecords(id) {
    const query = `{listCarrersFromAcreditor(id: ${id}){id,criteria,carrer{id,name}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar las carreras.');
      }

      this.setState({records: resp.data.data.listCarrersFromAcreditor});
    });
  }

  getUsers(search) {
    const query = `{listUsers(search: "${search}"){id,name,rol}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al consultar los usuarios.');
      }

      this.setState({users: resp.data.data.listUsers});
    });
  }

  handleCreateModal() {
    this.setState({openModalCarrers: false, openModal: true, modalTitle: 'Nueva carrera'});
  }

  handleDetailsModal() {
    this.setState({openDetailsModal: true});
    // Get saved files
    this.getEvidence('conaetOfficialRecordInstitutional');
    this.getEvidence('conaetEducativeModel');
    this.getEvidence('conaetAgeEducationalProgram');
    this.getEvidence('conaetRelevanceStudy');
    this.getEvidence('conaetGraduateGenerations');
    this.getEvidence('conaetStatisticsData');
    // Get assigned users
    this.getAcreditorUsers();
  }

  handleSubmitModal() {
    this.setState({loading: true});

    // Update or create
    if ('id' in this.state.selectedRecord) {
      this.updateRecord();
    } else {
      this.createRecord();
    }
  }

  createRecord() {
    const record = this.state.selectedRecord;
    // Add new record
    const query = `mutation{createCarrerFromAcreditor(id: ${this.state.acreditor.id}, name: "${record.name}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al crear el registro. Llena todos los campos obligatorios.');
      }

      // const response = resp.data.data.createCarrerFromAcreditor;
      // console.log(response);

      // Mensaje de exito
      message.success('El registro ha sido creado.');
      // Actualizar 
      this.setState({openModal: false, modalTitle: '', });
      this.getRecords(this.state.acreditor.id);
    });
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar carrera', selectedRecord: record.carrer});
  }

  updateRecord() {
    const selected = this.state.selectedRecord;
    const query = `mutation{updateCarrer(id: ${selected.id}, name: "${selected.name}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      // const response = resp.data.data.updateCarrer;
      //console.log(response);

      // Mensaje de exito
      message.success('El registro ha sido actualizado.');
      // Actualizar 
      this.setState({openModal: false, selectedRecord: {}, modalTitle: '', loading: false});
      this.getRecords(this.state.acreditor.id);
    });
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  deleteRecord(record) {
    const query = `mutation{unassignCarrer(id: ${record.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      message.info('El registro ha sido eliminado.');
      this.getRecords(this.state.acreditor.id);
    });
  }

  getAcreditorUsers() {
    const query = `mutation{listAcreditorUsers(acreditorId: ${this.state.acreditor.id}, tab: "${this.state.prevReqTab}"){id, user{name,rol}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      this.setState({acreditorUsers: resp.data.data.listAcreditorUsers});
    });
  }

  createAcreditorUser() {
    this.state.selectedRecord.users.forEach((user) => {
      const query = `mutation{createAcreditorUser(acreditorId: ${this.state.acreditor.id}, userId: ${user}, tab: "${this.state.prevReqTab}"){id}}`;
      
      axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
        if(resp.status !== 200) {
          return message.warning('Hubo un error al procesar tus credenciales');
        }
        
        if('errors' in resp.data) {
          console.log(resp.data);
          return message.warning('Error al asignar el usuario.');
        }
        
        this.getAcreditorUsers();
      });
    });

    message.info('Usuarios asignados.');
  }

  deleteUser(user) {
    console.log(user);
    const query = `mutation{deleteAcreditorUser(id: ${user.id}){id}}`;
    console.log(query);

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al borrar el usuario.');
      }

      message.info('Usuario eliminado.');
      this.getAcreditorUsers();
    });
  }

  duplicateRecord(record) {
    // const filter = this.state.allData.findIndex((el, index) => {
    //   if (record.id == el.id) {
    //     return el;
    //   }
    // });
    
    // let newRecords = this.state.allData;
    // let newRecord = {...newRecords[filter]};
    // newRecord.id = newRecords.length + 1;
    // newRecord.name = `${newRecord.name} (2)`;
    // newRecords.push(newRecord);
    // this.setState({allData: newRecords});
  }

  handleopenModalCarrers() {
    const query = `{listCarrers{id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al obtener las carreras.');
      }

      this.setState({openModalCarrers: true, carrers: resp.data.data.listCarrers});
    });
  }

  handleCancelModalCarrers() {
    this.setState({openModalCarrers: false});
  }

  handleAssignCarrer(record) {
    const query = `mutation{assignCarrer(carrerId: ${record.id}, acreditorId: ${this.state.acreditor.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al asignar el registro.');
      }

      this.setState({openModalCarrers: false, carrers: []});
      message.info('La carrera ha sido asignada.');
      this.getRecords(this.state.acreditor.id);
    });
  }

  updateDetails() {
    const record = this.state.acreditor;
    const reg1 = /(.*)(<p><br><\/p><h3>Evidencias<\/h3>.*)/;

    const conaetOfficialRecordInstitutional = this.state.conaetOfficialRecordInstitutional;
    let conaetOfficialRecordInstitutionalResponse = this.state.conaetOfficialRecordInstitutionalResponse;
    if (conaetOfficialRecordInstitutionalResponse && conaetOfficialRecordInstitutionalResponse.match(reg1) && conaetOfficialRecordInstitutionalResponse.match(reg1).length === 3) {
      conaetOfficialRecordInstitutionalResponse = conaetOfficialRecordInstitutionalResponse.replace(conaetOfficialRecordInstitutionalResponse.match(reg1)[2], '');
    }

    const conaetEducativeModel = this.state.conaetEducativeModel;
    let conaetEducativeModelResponse = this.state.conaetEducativeModelResponse;
    if (conaetEducativeModelResponse && conaetEducativeModelResponse.match(reg1) && conaetEducativeModelResponse.match(reg1).length === 3) {
      conaetEducativeModelResponse = conaetEducativeModelResponse.replace(conaetEducativeModelResponse.match(reg1)[2], '');
    }
    
    const conaetAgeEducationalProgram = this.state.conaetAgeEducationalProgram;
    let conaetAgeEducationalProgramResponse = this.state.conaetAgeEducationalProgramResponse;
    if (conaetAgeEducationalProgramResponse && conaetAgeEducationalProgramResponse.match(reg1) && conaetAgeEducationalProgramResponse.match(reg1).length === 3) {
      conaetAgeEducationalProgramResponse = conaetAgeEducationalProgramResponse.replace(conaetAgeEducationalProgramResponse.match(reg1)[2], '');
    }
    
    const conaetRelevanceStudy = this.state.conaetRelevanceStudy;
    let conaetRelevanceStudyResponse = this.state.conaetRelevanceStudyResponse;
    if (conaetRelevanceStudyResponse && conaetRelevanceStudyResponse.match(reg1) && conaetRelevanceStudyResponse.match(reg1).length === 3) {
      conaetRelevanceStudyResponse = conaetRelevanceStudyResponse.replace(conaetRelevanceStudyResponse.match(reg1)[2], '');
    }
    
    const conaetGraduateGenerations = this.state.conaetGraduateGenerations;
    let conaetGraduateGenerationsResponse = this.state.conaetGraduateGenerationsResponse;
    if (conaetGraduateGenerationsResponse && conaetGraduateGenerationsResponse.match(reg1) && conaetGraduateGenerationsResponse.match(reg1).length === 3) {
      conaetGraduateGenerationsResponse = conaetGraduateGenerationsResponse.replace(conaetGraduateGenerationsResponse.match(reg1)[2], '');
    }
    
    const conaetStatisticsData = this.state.conaetStatisticsData;
    let conaetStatisticsDataResponse = this.state.conaetStatisticsDataResponse;
    if (conaetStatisticsDataResponse && conaetStatisticsDataResponse.match(reg1) && conaetStatisticsDataResponse.match(reg1).length === 3) {
      conaetStatisticsDataResponse = conaetStatisticsDataResponse.replace(conaetStatisticsDataResponse.match(reg1)[2], '');
    }

    // Canet fields
    const query = `mutation{updateAcreditor(id: ${record.id}, shortName: "${record.shortName}", longName: "${record.longName}"){id}}`;
    // Responses
    const bodyRequest = {
      conaetOfficialRecordInstitutional: conaetOfficialRecordInstitutional, 
      conaetOfficialRecordInstitutionalResponse: conaetOfficialRecordInstitutionalResponse, 
      conaetEducativeModel: conaetEducativeModel, 
      conaetEducativeModelResponse: conaetEducativeModelResponse, 
      conaetAgeEducationalProgram: conaetAgeEducationalProgram, 
      conaetAgeEducationalProgramResponse: conaetAgeEducationalProgramResponse, 
      conaetRelevanceStudy: conaetRelevanceStudy, 
      conaetRelevanceStudyResponse: conaetRelevanceStudyResponse, 
      conaetGraduateGenerations: conaetGraduateGenerations, 
      conaetGraduateGenerationsResponse: conaetGraduateGenerationsResponse, 
      conaetStatisticsData: conaetStatisticsData, 
      conaetStatisticsDataResponse: conaetStatisticsDataResponse
    };

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {data: bodyRequest}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      const response = resp.data.data.updateAcreditor;
      console.log(response);
      message.info('Los datos han sido guardados.');
    });
  }

  updateDetailsAndClose() {
    this.updateDetails();
    this.setState({openDetailsModal: false});
  }

  statusColor(status) {
    switch(status) {
      case 'Pendiente':
        return '#FBDB14';
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#F5212D';
      case 'Autorizado':
        return '#52C51A';
      default:
        return '#FBDB14';
    }
  }


  // Upload evidence
  beforeUpload(file, kind) {
    // OfficialRecordInstitutional
    if (kind === 'conaetOfficialRecordInstitutional') {
      let files = [...this.state.conaetOfficialRecordInstitutionalTmp];
      files.push(file);
      this.setState({conaetOfficialRecordInstitutionalTmp: files});
    }
    // ConaetEducativeModel
    if (kind === 'conaetEducativeModel') {
      let files = [...this.state.conaetEducativeModelTmp];
      files.push(file);
      this.setState({conaetEducativeModelTmp: files});
    }
    // ConaetAgeEducationalProgram
    if (kind === 'conaetAgeEducationalProgram') {
      let files = [...this.state.conaetAgeEducationalProgramTmp];
      files.push(file);
      this.setState({conaetAgeEducationalProgramTmp: files});
    }
    // ConaetRelevanceStudy
    if (kind === 'conaetRelevanceStudy') {
      let files = [...this.state.conaetRelevanceStudyTmp];
      files.push(file);
      this.setState({conaetRelevanceStudyTmp: files});
    }
    // ConaetGraduateGenerations
    if (kind === 'conaetGraduateGenerations') {
      let files = [...this.state.conaetGraduateGenerationsTmp];
      files.push(file);
      this.setState({conaetGraduateGenerationsTmp: files});
    }
    // ConaetStatisticsData
    if (kind === 'conaetStatisticsData') {
      let files = [...this.state.conaetStatisticsDataTmp];
      files.push(file);
      this.setState({conaetStatisticsDataTmp: files});
    }

    // Return 
    return false;
  }
  
  
  removeUpload(file, kind) {
    // OfficialRecordInstitutional
    if (kind === 'conaetOfficialRecordInstitutional') {
      const index = this.state.conaetOfficialRecordInstitutionalTmp.indexOf(file);
      let newFileList = this.state.conaetOfficialRecordInstitutionalTmp.slice();
      newFileList.splice(index, 1);
      this.setState({conaetOfficialRecordInstitutionalTmp: newFileList});
    }

    // ConaetEducativeModel
    if (kind === 'conaetEducativeModel') {
      const index = this.state.conaetEducativeModelTmp.indexOf(file);
      let newFileList = this.state.conaetEducativeModelTmp.slice();
      newFileList.splice(index, 1);
      this.setState({conaetEducativeModelTmp: newFileList});
    }

    // ConaetAgeEducationalProgram
    if (kind === 'conaetAgeEducationalProgram') {
      const index = this.state.conaetAgeEducationalProgramTmp.indexOf(file);
      let newFileList = this.state.conaetAgeEducationalProgramTmp.slice();
      newFileList.splice(index, 1);
      this.setState({conaetAgeEducationalProgramTmp: newFileList});
    }

    // ConaetRelevanceStudy
    if (kind === 'conaetRelevanceStudy') {
      const index = this.state.conaetRelevanceStudyTmp.indexOf(file);
      let newFileList = this.state.conaetRelevanceStudyTmp.slice();
      newFileList.splice(index, 1);
      this.setState({conaetRelevanceStudyTmp: newFileList});
    }

    // ConaetGraduateGenerations
    if (kind === 'conaetGraduateGenerations') {
      const index = this.state.conaetGraduateGenerationsTmp.indexOf(file);
      let newFileList = this.state.conaetGraduateGenerationsTmp.slice();
      newFileList.splice(index, 1);
      this.setState({conaetGraduateGenerationsTmp: newFileList});
    }

    // ConaetStatisticsData
    if (kind === 'conaetStatisticsData') {
      const index = this.state.conaetStatisticsDataTmp.indexOf(file);
      let newFileList = this.state.conaetStatisticsDataTmp.slice();
      newFileList.splice(index, 1);
      this.setState({conaetStatisticsDataTmp: newFileList});
    }
  }

  
  handleUpload(kind) {
    // Validate files
    let fileList = [];
    // OfficialRecordInstitutional
    if (kind === 'conaetOfficialRecordInstitutional') {
      fileList = this.state.conaetOfficialRecordInstitutionalTmp;
    }
    // ConaetEducativeModel
    if (kind === 'conaetEducativeModel') {
      fileList = this.state.conaetEducativeModelTmp;
    }
    // conaetAgeEducationalProgram
    if (kind === 'conaetAgeEducationalProgram') {
      fileList = this.state.conaetAgeEducationalProgramTmp;
    }
    // conaetRelevanceStudy
    if (kind === 'conaetRelevanceStudy') {
      fileList = this.state.conaetRelevanceStudyTmp;
    }
    // conaetGraduateGenerations
    if (kind === 'conaetGraduateGenerations') {
      fileList = this.state.conaetGraduateGenerationsTmp;
    }
    // conaetStatisticsData
    if (kind === 'conaetStatisticsData') {
      fileList = this.state.conaetStatisticsDataTmp;
    }

    // Set files params
    let formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    this.setState({uploading: true});
    
    // Upload file
    const query = `mutation{createAcreditorEvidence(acreditorId: ${this.state.acreditor?.id}, kind: "${kind}"){id, kind, photo}}`;
    
    // Make request
    fetch(`${Configuration.apiServer}/api/v1?query=${query}`, {
      method: 'POST',
      body: formData,
      headers: this.headers
    })
    .then((res) => res.json())
    .then(() => {
      this.setState({uploading: false, fileList: []});
      // Alert Message
      message.success('La evidencia se ha subido correctamente.');
    })
    .catch(() => {
      this.setState({uploading: false, fileList: []});
      // Alert Message
      message.error('Hubo un error al subir la evidencia.');
    })
    .finally(() => {
      this.cleanFiles(kind);
      this.getEvidence(kind);
    });
  }

  
  cleanFiles(kind) {
    // OfficialRecordInstitutional
    if (kind === 'conaetOfficialRecordInstitutional') {
      this.setState({conaetOfficialRecordInstitutionalTmp: []});
    }
    // ConaetEducativeModel
    if (kind === 'conaetEducativeModel') {
      this.setState({conaetEducativeModelTmp: []});
    }
    // conaetAgeEducationalProgram
    if (kind === 'conaetAgeEducationalProgram') {
      this.setState({conaetAgeEducationalProgramTmp: []});
    }
    // conaetRelevanceStudy
    if (kind === 'conaetRelevanceStudy') {
      this.setState({conaetRelevanceStudyTmp: []});
    }
    // conaetGraduateGenerations
    if (kind === 'conaetGraduateGenerations') {
      this.setState({conaetGraduateGenerationsTmp: []});
    }
    // conaetStatisticsData
    if (kind === 'conaetStatisticsData') {
      this.setState({conaetStatisticsDataTmp: []});
    }
  }

  getEvidence(kind) {
    const query = `{listAcreditorEvidences(acreditorId: ${this.state.acreditor.id}, kind: "${kind}"){id,photo,kind,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al obtener la evidencia.');
      }

      const response = resp.data.data.listAcreditorEvidences;
      // OfficialRecordInstitutional
      if (kind === 'conaetOfficialRecordInstitutional') {
        // Update state
        this.setState({conaetOfficialRecordInstitutionalFiles: response});
        // Add to description
        this.addEvidenceToResponse(response, 'conaetOfficialRecordInstitutional');
      }
      // ConaetEducativeModel
      if (kind === 'conaetEducativeModel') {
        this.setState({conaetEducativeModelFiles: response});
        this.addEvidenceToResponse(response, 'conaetEducativeModel');
      }
      // conaetAgeEducationalProgram
      if (kind === 'conaetAgeEducationalProgram') {
        this.setState({conaetAgeEducationalProgramFiles: response});
        this.addEvidenceToResponse(response, 'conaetAgeEducationalProgram');
      }
      // conaetRelevanceStudy
      if (kind === 'conaetRelevanceStudy') {
        this.setState({conaetRelevanceStudyFiles: response});
        this.addEvidenceToResponse(response, 'conaetRelevanceStudy');
      }
      // conaetGraduateGenerations
      if (kind === 'conaetGraduateGenerations') {
        this.setState({conaetGraduateGenerationsFiles: response});
        this.addEvidenceToResponse(response, 'conaetGraduateGenerations');
      }
      // conaetStatisticsData
      if (kind === 'conaetStatisticsData') {
        this.setState({conaetStatisticsDataFiles: response});
        this.addEvidenceToResponse(response, 'conaetStatisticsData');
      }
    });
  }

  addEvidenceToResponse(files, kind) {
    let response = this.state[`${kind}Response`];
    
    // Add title for evidences
    if (!response || ( !response.match('<br/><h3>Evidencias</h3>') && !response.match('<p><br></p><h3>Evidencias</h3>') )) {
      let firsResponse = "";
      if (response) {
        firsResponse = response;
      }
      response = `${firsResponse}<br/><h3>Evidencias</h3>`;
    }

    // Clean all evidences
    // When is live edition
    const reg1 = /(.*)(<br\/><h3>Evidencias<\/h3>)(.*)/;
    const allFiles = response.match(reg1);
    if (allFiles && allFiles.length === 4) {
      response = response.replace(allFiles[3], '');

      // Validate file by file
      files.forEach((file, index) => {
        // Add new evidence
        response = `${response}<p><a href="https://dcea-api.innovaster.co${file.photo}" rel="noopener noreferrer" target="_blank">Evidencia #${index+1}: ${file.name}</a></p>`;
      });
      // console.log(response);
      this.setState({[`${kind}Response`]: response});
    }

    // Clean all evidences
    // After saved in DB
    const reg2 = /(.*)(<p><br><\/p><h3>Evidencias<\/h3>)(.*)/;
    const allFiles2 = response.match(reg2);
    if (allFiles2 && allFiles2.length === 4) {
      response = response.replace(allFiles2[3], '');

      // Validate file by file
      files.forEach((file, index) => {
        // Add new evidence
        response = `${response}<p><a href="https://dcea-api.innovaster.co${file.photo}" rel="noopener noreferrer" target="_blank">Evidencia #${index+1}: ${file.name}</a></p>`;
      });
      // console.log(response);
      this.setState({[`${kind}Response`]: response});
    }
  }

  deleteEvidence(file) {
    const query = `mutation{deleteAcreditorEvidence(id: ${file.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al borrar la evidencia.');
      }

      // Update list
      this.getEvidence(file.kind);
    });
  }

  testUpload() {
    let resp = this.state.conaetOfficialRecordInstitutionalResponse;
    resp = `${resp} <p><a href="visitapp.la">Evidencia #1: nombre_archivo.png</a></p>`;

    this.setState({conaetOfficialRecordInstitutionalResponse: resp});
  }

  testUploadRemove() {
    console.log('REMOVE');
    let resp = this.state.conaetOfficialRecordInstitutionalResponse;
    console.log(resp);
    resp = resp.replace('<p><a href="visitapp.la" rel="noopener noreferrer" target="_blank">Evidencia #1: nombre_archivo.png</a></p>', '');

    this.setState({conaetOfficialRecordInstitutionalResponse: resp});
  }


  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={() => {}}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false}
        >
          <div>
            <div className="ant-form-item">
              <div className="ant-row ant-form-item-row">
                <div className="ant-col ant-col-8 ant-form-item-label">
                  <label className="ant-form-item-required" title="Nombre">Nombre</label>
                </div>
                <div className="ant-col ant-col-16 ant-form-item-control">
                  <Input value={this.state.selectedRecord?.name} onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, name: val.target.value}})} />
                </div>
              </div>
            </div>

            <div className='ant-row ant-form-item-row' style={{justifyContent: 'center', marginTop: '10px'}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" onClick={this.handleSubmitModal.bind(this)}>Guardar</Button>
            </div>
          </div>
        </Modal>


        {/* Modal para detalles de organo */}
        <Modal
          open={this.state.openDetailsModal}
          title={'Requisitos previos'}
          onOk={() => {}}
          onCancel={() => { this.setState({openDetailsModal: false}) }}
          footer={false}
          width={window.innerWidth * 0.8}
        >
          <div>
            <div style={{marginBottom: 20, paddingBottom: 20, display: 'flex', justifyContent: 'center', borderBottom: '1px solid #ccc'}}>
              <Button ghost style={{marginBottom: 5, marginLeft: 5, borderColor: this.state.prevReqTab === 'registroOficial' ? '#1C90FF' : '#D9D9D9', color: this.state.prevReqTab === 'registroOficial' ? '#1C90FF' : '#212121'}} onClick={() => { this.setState({prevReqTab: 'registroOficial'}) }}>Registro oficial</Button>
              <Button ghost style={{marginBottom: 5, marginLeft: 5, borderColor: this.state.prevReqTab === 'modeloEducativo' ? '#1C90FF' : '#D9D9D9', color: this.state.prevReqTab === 'modeloEducativo' ? '#1C90FF' : '#212121'}} onClick={() => { this.setState({prevReqTab: 'modeloEducativo'}) }}>Modelo educativo</Button>
              <Button ghost style={{marginBottom: 5, marginLeft: 5, borderColor: this.state.prevReqTab === 'antiguedad' ? '#1C90FF' : '#D9D9D9', color: this.state.prevReqTab === 'antiguedad' ? '#1C90FF' : '#212121'}} onClick={() => { this.setState({prevReqTab: 'antiguedad'}) }}>Antigüedad</Button>
              <Button ghost style={{marginBottom: 5, marginLeft: 5, borderColor: this.state.prevReqTab === 'estudioPertinencia' ? '#1C90FF' : '#D9D9D9', color: this.state.prevReqTab === 'estudioPertinencia' ? '#1C90FF' : '#212121'}} onClick={() => { this.setState({prevReqTab: 'estudioPertinencia'}) }}>Estudio de pertinencia</Button>
              <Button ghost style={{marginBottom: 5, marginLeft: 5, borderColor: this.state.prevReqTab === 'generacionesEgreso' ? '#1C90FF' : '#D9D9D9', color: this.state.prevReqTab === 'generacionesEgreso' ? '#1C90FF' : '#212121'}} onClick={() => { this.setState({prevReqTab: 'generacionesEgreso'}) }}>Generaciones de egreso</Button>
              <Button ghost style={{marginBottom: 5, marginLeft: 5, borderColor: this.state.prevReqTab === 'datosEstadisticos' ? '#1C90FF' : '#D9D9D9', color: this.state.prevReqTab === 'datosEstadisticos' ? '#1C90FF' : '#212121'}} onClick={() => { this.setState({prevReqTab: 'datosEstadisticos'}) }}>Datos estadísticos</Button>
            </div>

            <div className="ant-form-item" style={{borderBottom: '1px dotted #ddd', marginBottom: 20}}>
              <div className="ant-row ant-form-item-row">
                <div className="ant-col ant-col-24 ant-form-item-label">
                  <label className="ant-form-item" style={{marginBottom: '0px'}} title="Asignar a usuario">Agregar usuarios</label>
                </div>
                <div className="ant-col ant-col-20 ant-form-item-control">
                  <Select
                    showSearch
                    mode='multiple'
                    placeholder="Elige a un usuario"
                    optionFilterProp="children"
                    onChange={(val) => this.setState({selectedRecord: {...this.state.acreditor, users: val, tab: this.state.prevReqTab}})}
                    onSearch={() => {}}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.users.map((item) => {
                      if (item && item?.id) {
                        return ({key: `${item.id}-opt-user`, value: item.id, label: `${item.name}. Rol: ${item.rol}`})
                      }
                    })}
                  />
                </div>
                <div className="ant-col ant-col-4 ant-form-item-control">
                  <Button type='primary' icon={<PlusOutlined />} block onClick={() => this.createAcreditorUser()}>Agregar usuarios</Button>
                </div>
              </div>

              <br />

              <div style={{marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-start'}}>
                <span style={{marginRight: 5}}>Asignado a</span>
                {this.state.acreditorUsers.map((u) => {
                  return (<div key={`ind-user-${u.id}`} style={{border: '1px solid #ccc', borderRadius: 5, marginRight: 5, padding: 5}}> 
                    <div>
                      <span style={{marginRight: 15}}>{u.user.name}</span>
                      <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteUser.bind(this, u)}>
                        <DeleteOutlined style={{color: '#ff4d4f'}} />
                      </Popconfirm>
                    </div>
                    <p style={{marginBottom: 0, color: "#777"}}>Rol: {u.user.rol}</p>
                  </div>);
                })}
              </div>
            </div>
            
            {/* Registro oficial institucional */}
            <div style={{display: this.state.prevReqTab === 'registroOficial' ? 'block' : 'none'}}>
              <div className="ant-form-item" style={{marginBottom: 50}}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-24 ant-form-item-label">
                    <label className="ant-form-item-required" title="Registro oficial"><b>Registro oficial institucional</b></label>
                  </div>
                  <div className="ant-col ant-col-24 ant-form-item-control">
                    <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetOfficialRecordInstitutional} onChange={(val) => {this.setState({conaetOfficialRecordInstitutional: val})}} style={{height: 200}} />
                  </div>
                </div>
              </div>

              <br />

              <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                <div className="ant-form-item" style={{marginBottom: 50}}>
                  <div className="ant-row ant-form-item-row">

                    <div className="ant-col ant-col-24 ant-form-item-label">
                      <label className="" title="Registro oficial"><WechatOutlined style={{marginRight: 5}} /> <b>Respuesta</b></label>

                      <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                        <p style={{marginBottom: 0}}>1. Responde a cada inciso descrito arriba.</p>
                        <p style={{marginBottom: 0}}>2. Busca la evidencia desde tu computadora.</p>
                        <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                      </div>
                    </div>

                    <div className="ant-col ant-col-24 ant-form-item-control">
                      <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetOfficialRecordInstitutionalResponse} onChange={(val) => {this.setState({conaetOfficialRecordInstitutionalResponse: val})}} style={{height: 200}} />
                    </div>
                  </div>
                </div>

                <div className="ant-form-item" style={{marginBottom: 100}}>
                  <br />
                  <label className="ant-form-item" title="Registro oficial"><b>Sube tu evidencia</b></label>
                  <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                    <p style={{marginBottom: 0}}>1. Selecciona tu evidencia desde tu computadora.</p>
                    <p style={{marginBottom: 0}}>2. Confirma y sube tus archivos.</p>
                    <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                  </div>

                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24" style={{paddingBottom: 10}}>
                      <div>
                        <Upload onRemove={(file) => { this.removeUpload(file, 'conaetOfficialRecordInstitutional') }} beforeUpload={(file) => { this.beforeUpload(file, 'conaetOfficialRecordInstitutional') }} fileList={this.state.conaetOfficialRecordInstitutionalTmp}>
                          <Button type='primary' icon={<UploadOutlined />} block>Adjuntar nueva evidencia</Button>
                        </Upload>

                        {this.state.conaetOfficialRecordInstitutionalTmp.length > 0 ?
                          <Button icon={<UploadOutlined />} style={{backgroundColor: '#62DEA1', color: '#212121', marginTop: 20, width: '100%'}} onClick={() => this.handleUpload('conaetOfficialRecordInstitutional')}>Confirma y sube la evidencia</Button>
                        : null}
                      </div>
                    </div>
                    
                    {/* Uploaded files */}
                    {this.state.conaetOfficialRecordInstitutionalFiles && this.state.conaetOfficialRecordInstitutionalFiles.length > 0 ? 
                      <div className="ant-col ant-col-25 ant-form-item-control">
                        <br/>
                        <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia que haz subido</b></label>
                        <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                          <p style={{marginBottom: 0}}>1. Elimina la evidencia que haz agregado.</p>
                          <p style={{marginBottom: 0}}>2. El sistema borrará la evidencia automáticamente al final de tu respuesta.</p>
                        </div>

                        <List
                          className="demo-loadmore-list"
                          loading={false}
                          itemLayout="horizontal"
                          dataSource={this.state.conaetOfficialRecordInstitutionalFiles}
                          style={{width: '100%', paddingLeft: 5}}
                          renderItem={(item, index) => (
                            <List.Item
                              actions={[
                                <Button key={`file-edit-${item.id}`}>
                                  <a href={`${Configuration.apiServer}${item.photo}`} target="_blank" rel="noreferrer">Ver</a>
                                </Button>,
                                <Button key={`file-delete-${item.id}`} danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteEvidence.bind(this, item)}>Borrar</Popconfirm>
                                </Button>
                              ]}
                            >
                              {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                            </List.Item>
                          )}
                        />
                      </div>
                    : null}

                  </div>
                </div>
              </div>
            </div>
                      

            {/* Modelo educativo */}
            <div style={{display: this.state.prevReqTab === 'modeloEducativo' ? 'block' : 'none'}}>
              <div className="ant-form-item" style={{marginBottom: 50}}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-24 ant-form-item-label">
                    <label className="ant-form-item-required" title="Nombre"><strong>Modelo educativo</strong></label>
                  </div>

                  <div className="ant-col ant-col-24 ant-form-item-control">
                    <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetEducativeModel} onChange={(val) => {this.setState({conaetEducativeModel: val})}} style={{height: 200}} />
                  </div>
                </div>
              </div>

              <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                <div className="ant-form-item" style={{marginBottom: 50}}>
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24 ant-form-item-label">
                      <label className="" title="Registro oficial"><WechatOutlined style={{marginRight: 5}} /> <b>Respuesta</b></label>

                      <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                        <p style={{marginBottom: 0}}>1. Responde a cada inciso descrito arriba.</p>
                        <p style={{marginBottom: 0}}>2. Busca la evidencia desde tu computadora.</p>
                        <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                      </div>
                    </div>

                    <div className="ant-col ant-col-24 ant-form-item-control">
                      <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetEducativeModelResponse} onChange={(val) => {this.setState({conaetEducativeModelResponse: val})}} style={{height: 200}} />
                    </div>
                  </div>
                </div>

                <div className="ant-form-item" style={{marginBottom: 100}}>
                  <br />
                  <label className="ant-form-item" title="Registro oficial"><b>Sube tu evidencia</b></label>
                  <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                    <p style={{marginBottom: 0}}>1. Selecciona tu evidencia desde tu computadora.</p>
                    <p style={{marginBottom: 0}}>2. Confirma y sube tus archivos.</p>
                    <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                  </div>


                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24" style={{paddingBottom: 10}}>
                      <div>
                        <Upload onRemove={(file) => { this.removeUpload(file, 'conaetEducativeModel') }} beforeUpload={(file) => { this.beforeUpload(file, 'conaetEducativeModel') }} fileList={this.state.conaetEducativeModelTmp}>
                          <Button type='primary' icon={<UploadOutlined />} block>Adjuntar nueva evidencia</Button>
                        </Upload>

                        {this.state.conaetEducativeModelTmp.length > 0 ?
                          <Button icon={<UploadOutlined />} style={{backgroundColor: '#62DEA1', color: '#212121', marginTop: 20, width: '100%'}} onClick={() => this.handleUpload('conaetEducativeModel')}>Confirma y sube la evidencia</Button>
                        : null}
                      </div>
                    </div>
                    
                    {/* Uploaded files */}
                    {this.state.conaetEducativeModelFiles && this.state.conaetEducativeModelFiles.length > 0 ? 
                      <div className="ant-col ant-col-25 ant-form-item-control">
                        <br/>
                        <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia que haz subido</b></label>
                        <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                          <p style={{marginBottom: 0}}>1. Elimina la evidencia que haz agregado.</p>
                          <p style={{marginBottom: 0}}>2. El sistema borrará la evidencia automáticamente al final de tu respuesta.</p>
                        </div>

                        <List
                          className="demo-loadmore-list"
                          loading={false}
                          itemLayout="horizontal"
                          dataSource={this.state.conaetEducativeModelFiles}
                          style={{width: '100%', paddingLeft: 5}}
                          renderItem={(item, index) => (
                            <List.Item
                              actions={[
                                <Button key={`file-edit-${item.id}`}>
                                  <a href={`${Configuration.apiServer}${item.photo}`} target="_blank" rel="noreferrer">Ver</a>
                                </Button>,
                                <Button key={`file-delete-${item.id}`} danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteEvidence.bind(this, item)}>Borrar</Popconfirm>
                                </Button>
                              ]}
                            >
                              {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                            </List.Item>
                          )}
                        />
                      </div>
                    : null}
                  </div>

                </div>
              </div>
            </div>

            
            {/* Antigüedad del programa educativo */}
            <div style={{display: this.state.prevReqTab === 'antiguedad' ? 'block' : 'none'}}>
              <div className="ant-form-item" style={{marginBottom: 50}}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-24 ant-form-item-label">
                    <label className="ant-form-item-required" title="Nombre"><strong>Antigüedad del programa educativo</strong></label>
                  </div>

                  <div className="ant-col ant-col-24 ant-form-item-control">
                    <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetAgeEducationalProgram} onChange={(val) => {this.setState({conaetAgeEducationalProgram: val})}} style={{height: 200}} />
                  </div>
                </div>
              </div>
              
              <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                <div className="ant-form-item" style={{marginBottom: 50}}>
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24 ant-form-item-label">
                      <label className="" title="Registro oficial"><WechatOutlined style={{marginRight: 5}} /> <b>Respuesta</b></label>

                      <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                        <p style={{marginBottom: 0}}>1. Responde a cada inciso descrito arriba.</p>
                        <p style={{marginBottom: 0}}>2. Busca la evidencia desde tu computadora.</p>
                        <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                      </div>
                    </div>

                    <div className="ant-col ant-col-24 ant-form-item-control">
                      <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetAgeEducationalProgramResponse} onChange={(val) => {this.setState({conaetAgeEducationalProgramResponse: val})}} style={{height: 200}} />
                    </div>
                  </div>
                </div>

                <div className="ant-form-item" style={{marginBottom: 100}}>
                  <br />
                  <label className="ant-form-item" title="Registro oficial"><b>Sube tu evidencia</b></label>
                  <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                    <p style={{marginBottom: 0}}>1. Selecciona tu evidencia desde tu computadora.</p>
                    <p style={{marginBottom: 0}}>2. Confirma y sube tus archivos.</p>
                    <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                  </div>

                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24" style={{paddingBottom: 10}}>
                      <div>
                        <Upload onRemove={(file) => { this.removeUpload(file, 'conaetAgeEducationalProgram') }} beforeUpload={(file) => { this.beforeUpload(file, 'conaetAgeEducationalProgram') }} fileList={this.state.conaetAgeEducationalProgramTmp}>
                          <Button type='primary' icon={<UploadOutlined />} block>Adjuntar nueva evidencia</Button>
                        </Upload>

                        {this.state.conaetAgeEducationalProgramTmp.length > 0 ?
                          <Button icon={<UploadOutlined />} style={{backgroundColor: '#62DEA1', color: '#212121', marginTop: 20, width: '100%'}} onClick={() => this.handleUpload('conaetAgeEducationalProgram')}>Confirma y sube la evidencia</Button>
                        : null}
                      </div>
                    </div>
                    
                    {/* Uploaded files */}
                    {this.state.conaetAgeEducationalProgramFiles && this.state.conaetAgeEducationalProgramFiles.length > 0 ? 
                      <div className="ant-col ant-col-25 ant-form-item-control">
                        <br/>
                        <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia que haz subido</b></label>
                        <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                          <p style={{marginBottom: 0}}>1. Elimina la evidencia que haz agregado.</p>
                          <p style={{marginBottom: 0}}>2. El sistema borrará la evidencia automáticamente al final de tu respuesta.</p>
                        </div>

                        <List
                          className="demo-loadmore-list"
                          loading={false}
                          itemLayout="horizontal"
                          dataSource={this.state.conaetAgeEducationalProgramFiles}
                          style={{width: '100%', paddingLeft: 5}}
                          renderItem={(item, index) => (
                            <List.Item
                              actions={[
                                <Button key={`file-edit-${item.id}`}>
                                  <a href={`${Configuration.apiServer}${item.photo}`} target="_blank" rel="noreferrer">Ver</a>
                                </Button>,
                                <Button key={`file-delete-${item.id}`} danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteEvidence.bind(this, item)}>Borrar</Popconfirm>
                                </Button>
                              ]}
                            >
                              {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                            </List.Item>
                          )}
                        />
                      </div>
                    : null}

                  </div>
                </div>
              </div>
            </div>


            {/* Estudio de pertinencia del programa educativo */}
            <div style={{display: this.state.prevReqTab === 'estudioPertinencia' ? 'block' : 'none'}}>
              <div className="ant-form-item" style={{marginBottom: 50}}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-24 ant-form-item-label">
                    <label className="ant-form-item-required" title="Nombre"><strong>Estudio de pertinencia del programa educativo</strong></label>
                  </div>

                  <div className="ant-col ant-col-24 ant-form-item-control">
                    <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetRelevanceStudy} onChange={(val) => {this.setState({conaetRelevanceStudy: val})}} style={{height: 200}} />
                  </div>
                </div>
              </div>
              
              <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                <div className="ant-form-item" style={{marginBottom: 50}}>
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24 ant-form-item-label">
                      <label className="" title="Registro oficial"><WechatOutlined style={{marginRight: 5}} /> <b>Respuesta</b></label>

                      <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                        <p style={{marginBottom: 0}}>1. Responde a cada inciso descrito arriba.</p>
                        <p style={{marginBottom: 0}}>2. Busca la evidencia desde tu computadora.</p>
                        <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                      </div>
                    </div>

                    <div className="ant-col ant-col-24 ant-form-item-control">
                      <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetRelevanceStudyResponse} onChange={(val) => {this.setState({conaetRelevanceStudyResponse: val})}} style={{height: 200}} />
                    </div>
                  </div>
                </div>

                <div className="ant-form-item" style={{marginBottom: 100}}>
                  <br />
                  <label className="ant-form-item" title="Registro oficial"><b>Sube tu evidencia</b></label>
                  <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                    <p style={{marginBottom: 0}}>1. Selecciona tu evidencia desde tu computadora.</p>
                    <p style={{marginBottom: 0}}>2. Confirma y sube tus archivos.</p>
                    <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                  </div>

                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24" style={{paddingBottom: 10}}>
                      <div>
                        <Upload onRemove={(file) => { this.removeUpload(file, 'conaetRelevanceStudy') }} beforeUpload={(file) => { this.beforeUpload(file, 'conaetRelevanceStudy') }} fileList={this.state.conaetRelevanceStudyTmp}>
                          <Button type='primary' icon={<UploadOutlined />} block>Adjuntar nueva evidencia</Button>
                        </Upload>

                        {this.state.conaetRelevanceStudyTmp.length > 0 ?
                          <Button icon={<UploadOutlined />} style={{backgroundColor: '#62DEA1', color: '#212121', marginTop: 20, width: '100%'}} onClick={() => this.handleUpload('conaetRelevanceStudy')}>Confirma y sube la evidencia</Button>
                        : null}
                      </div>
                    </div>
                    
                    {/* Uploaded files */}
                    {this.state.conaetRelevanceStudyFiles && this.state.conaetRelevanceStudyFiles.length > 0 ? 
                      <div className="ant-col ant-col-25 ant-form-item-control">
                        <br/>
                        <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia que haz subido</b></label>
                        <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                          <p style={{marginBottom: 0}}>1. Elimina la evidencia que haz agregado.</p>
                          <p style={{marginBottom: 0}}>2. El sistema borrará la evidencia automáticamente al final de tu respuesta.</p>
                        </div>

                        <List
                          className="demo-loadmore-list"
                          loading={false}
                          itemLayout="horizontal"
                          dataSource={this.state.conaetRelevanceStudyFiles}
                          style={{width: '100%', paddingLeft: 5}}
                          renderItem={(item, index) => (
                            <List.Item
                              actions={[
                                <Button key={`file-edit-${item.id}`}>
                                  <a href={`${Configuration.apiServer}${item.photo}`} target="_blank" rel="noreferrer">Ver</a>
                                </Button>,
                                <Button key={`file-delete-${item.id}`} danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteEvidence.bind(this, item)}>Borrar</Popconfirm>
                                </Button>
                              ]}
                            >
                              {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                            </List.Item>
                          )}
                        />
                      </div>
                    : null}

                  </div>
                </div>
              </div>
            </div>

            
            {/* Generaciones de egreso del programa educativo */}
            <div style={{display: this.state.prevReqTab === 'generacionesEgreso' ? 'block' : 'none'}}>
              <div className="ant-form-item" style={{marginBottom: 50}}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-24 ant-form-item-label">
                    <label className="ant-form-item-required" title="Nombre"><strong>Generaciones de egreso del programa educativo</strong></label>
                  </div>

                  <div className="ant-col ant-col-24 ant-form-item-control">
                    <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetGraduateGenerations} onChange={(val) => {this.setState({conaetGraduateGenerations: val})}} style={{height: 200}} />
                  </div>
                </div>
              </div>
              
              <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                <div className="ant-form-item" style={{marginBottom: 50}}>
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24 ant-form-item-label">
                      <label className="" title="Registro oficial"><WechatOutlined style={{marginRight: 5}} /> <b>Respuesta</b></label>

                      <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                        <p style={{marginBottom: 0}}>1. Responde a cada inciso descrito arriba.</p>
                        <p style={{marginBottom: 0}}>2. Busca la evidencia desde tu computadora.</p>
                        <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                      </div>
                    </div>

                    <div className="ant-col ant-col-24 ant-form-item-control">
                      <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetGraduateGenerationsResponse} onChange={(val) => {this.setState({conaetGraduateGenerationsResponse: val})}} style={{height: 200}} />
                    </div>
                  </div>
                </div>

                <div className="ant-form-item" style={{marginBottom: 100}}>
                  <br />
                  <label className="ant-form-item" title="Registro oficial"><b>Sube tu evidencia</b></label>
                  <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                    <p style={{marginBottom: 0}}>1. Selecciona tu evidencia desde tu computadora.</p>
                    <p style={{marginBottom: 0}}>2. Confirma y sube tus archivos.</p>
                    <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                  </div>

                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24" style={{paddingBottom: 10}}>
                      <div>
                        <Upload onRemove={(file) => { this.removeUpload(file, 'conaetGraduateGenerations') }} beforeUpload={(file) => { this.beforeUpload(file, 'conaetGraduateGenerations') }} fileList={this.state.conaetGraduateGenerationsTmp}>
                          <Button type='primary' icon={<UploadOutlined />} block>Adjuntar nueva evidencia</Button>
                        </Upload>

                        {this.state.conaetGraduateGenerationsTmp.length > 0 ?
                          <Button icon={<UploadOutlined />} style={{backgroundColor: '#62DEA1', color: '#212121', marginTop: 20, width: '100%'}} onClick={() => this.handleUpload('conaetGraduateGenerations')}>Confirma y sube la evidencia</Button>
                        : null}
                      </div>
                    </div>
                    
                    {/* Uploaded files */}
                    {this.state.conaetGraduateGenerationsFiles && this.state.conaetGraduateGenerationsFiles.length > 0 ? 
                      <div className="ant-col ant-col-25 ant-form-item-control">
                        <br/>
                        <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia que haz subido</b></label>
                        <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                          <p style={{marginBottom: 0}}>1. Elimina la evidencia que haz agregado.</p>
                          <p style={{marginBottom: 0}}>2. El sistema borrará la evidencia automáticamente al final de tu respuesta.</p>
                        </div>

                        <List
                          className="demo-loadmore-list"
                          loading={false}
                          itemLayout="horizontal"
                          dataSource={this.state.conaetGraduateGenerationsFiles}
                          style={{width: '100%', paddingLeft: 5}}
                          renderItem={(item, index) => (
                            <List.Item
                              actions={[
                                <Button key={`file-edit-${item.id}`}>
                                  <a href={`${Configuration.apiServer}${item.photo}`} target="_blank" rel="noreferrer">Ver</a>
                                </Button>,
                                <Button key={`file-delete-${item.id}`} danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteEvidence.bind(this, item)}>Borrar</Popconfirm>
                                </Button>
                              ]}
                            >
                              {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                            </List.Item>
                          )}
                        />
                      </div>
                    : null}

                  </div>
                </div>
              </div>
            </div>

            
            {/* Datos estadísticos del programa educativo */}
            <div style={{display: this.state.prevReqTab === 'datosEstadisticos' ? 'block' : 'none'}}>
              <div className="ant-form-item" style={{marginBottom: 50}}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-24 ant-form-item-label">
                    <label className="ant-form-item-required" title="Nombre"><strong>Datos estadísticos del programa educativo</strong></label>
                  </div>

                  <div className="ant-col ant-col-24 ant-form-item-control">
                    <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetStatisticsData} onChange={(val) => {this.setState({conaetStatisticsData: val})}} style={{height: 200}} />
                  </div>
                </div>
              </div>

              <div style={{backgroundColor: '#F9FCFF', border: '1px solid #E8F4FF', padding: 15, borderRadius: 5}}>
                <div className="ant-form-item" style={{marginBottom: 50}}>
                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24 ant-form-item-label">
                      <label className="" title="Registro oficial"><WechatOutlined style={{marginRight: 5}} /> <b>Respuesta</b></label>

                      <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                        <p style={{marginBottom: 0}}>1. Responde a cada inciso descrito arriba.</p>
                        <p style={{marginBottom: 0}}>2. Busca la evidencia desde tu computadora.</p>
                        <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                      </div>
                    </div>

                    <div className="ant-col ant-col-24 ant-form-item-control">
                      <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.conaetStatisticsDataResponse} onChange={(val) => {this.setState({conaetStatisticsDataResponse: val})}} style={{height: 200}} />
                    </div>
                  </div>
                </div>

                <div className="ant-form-item" style={{marginBottom: 100}}>
                  <br />
                  <label className="ant-form-item" title="Registro oficial"><b>Sube tu evidencia</b></label>
                  <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                    <p style={{marginBottom: 0}}>1. Selecciona tu evidencia desde tu computadora.</p>
                    <p style={{marginBottom: 0}}>2. Confirma y sube tus archivos.</p>
                    <p style={{marginBottom: 0}}>3. El sistema agregará la evidencia automáticamente al final de tu respuesta.</p>
                  </div>

                  <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-col-24" style={{paddingBottom: 10}}>
                      <div>
                        <Upload onRemove={(file) => { this.removeUpload(file, 'conaetStatisticsData') }} beforeUpload={(file) => { this.beforeUpload(file, 'conaetStatisticsData') }} fileList={this.state.conaetStatisticsDataTmp}>
                          <Button type='primary' icon={<UploadOutlined />} block>Adjuntar nueva evidencia</Button>
                        </Upload>

                        {this.state.conaetStatisticsDataTmp.length > 0 ?
                          <Button icon={<UploadOutlined />} style={{backgroundColor: '#62DEA1', color: '#212121', marginTop: 20, width: '100%'}} onClick={() => this.handleUpload('conaetStatisticsData')}>Confirma y sube la evidencia</Button>
                        : null}
                      </div>
                    </div>
                    
                    {/* Uploaded files */}
                    {this.state.conaetStatisticsDataFiles && this.state.conaetStatisticsDataFiles.length > 0 ? 
                      <div className="ant-col ant-col-25 ant-form-item-control">
                        <br/>
                        <label className="ant-form-item" title="Registro oficial" style={{marginBottom: 0}}><b>Evidencia que haz subido</b></label>
                        <div style={{backgroundColor: '#FFF0B9', padding: 5, borderRadius: 5, marginBottom: 10}}>
                          <p style={{marginBottom: 0}}>1. Elimina la evidencia que haz agregado.</p>
                          <p style={{marginBottom: 0}}>2. El sistema borrará la evidencia automáticamente al final de tu respuesta.</p>
                        </div>

                        <List
                          className="demo-loadmore-list"
                          loading={false}
                          itemLayout="horizontal"
                          dataSource={this.state.conaetStatisticsDataFiles}
                          style={{width: '100%', paddingLeft: 5}}
                          renderItem={(item, index) => (
                            <List.Item
                              actions={[
                                <Button key={`file-edit-${item.id}`}>
                                  <a href={`${Configuration.apiServer}${item.photo}`} target="_blank" rel="noreferrer">Ver</a>
                                </Button>,
                                <Button key={`file-delete-${item.id}`} danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteEvidence.bind(this, item)}>Borrar</Popconfirm>
                                </Button>
                              ]}
                            >
                              {index+1}. <Skeleton title={false} loading={false}><FileOutlined /></Skeleton> {item.name}
                            </List.Item>
                          )}
                        />
                      </div>
                    : null}

                  </div>
                </div>
              </div>
            </div>


            <div className='ant-row ant-form-item-row' style={{justifyContent: 'center', marginTop: 60}}>
              <Button onClick={() => { this.setState({openDetailsModal: false}) }} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" style={{marginRight: 5}} onClick={this.updateDetails.bind(this)}>Guardar</Button>
              <Button type="primary" onClick={this.updateDetailsAndClose.bind(this)}>Guardar y cerrar</Button>
            </div>
          </div>
        </Modal>


        {/* Moal for assign carrer */}
        <Modal
          open={this.state.openModalCarrers}
          title={'Asignar carrera'}
          onOk={() => {}}
          onCancel={this.handleCancelModalCarrers.bind(this)}
          footer={false}
        >

          <div>
            <div style={{borderBottom: 'solid #ddd 1px', paddingBottom: 10, display: 'flex', justifyContent: 'center'}}>
              <Button icon={<PlusOutlined />} size="large" style={{backgroundColor: '#1B3C6A', color: '#fff'}} onClick={this.handleCreateModal.bind(this)}>Nueva carrera</Button>
            </div>

            <List
            itemLayout="horizontal"
            dataSource={this.state.carrers}
            renderItem={item => (     
              <List.Item key={`car-${item.id}`}>
                <List.Item.Meta                      
                  title={<p style={{fontSize: '1.1em'}}>{item.name}</p>}
                  style={{textAlign: 'left'}}
                />
                
                <Tooltip placement="top" title={'Asignar'}>
                  <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.handleAssignCarrer.bind(this, item)}><ArrowRightOutlined /></Button>
                </Tooltip>
              </List.Item>
            )}/>
          </div>
        </Modal>
        
        {/* Top menu */}
        <TopMenu />

        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div style={{padding: 24, textAlign: 'center'}}>
              <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Ir a</Breadcrumb.Item>
                <Breadcrumb.Item> <a href="/organos-acreditadores" style={{background: '#1890ff', color: 'white', padding: 5, marginTop: 5, marginBottom: 5}}>Organos acreditadores</a> </Breadcrumb.Item>
              </Breadcrumb>

              <br /><br /><br />

              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <h3 style={{marginBottom: 0}}>Carreras asignadas</h3>
                
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end', width: '50%'}}>
                  {this.state.acreditor?.shortName?.toLowerCase() === 'conaet' ? 
                    <Button icon={<FileTextOutlined />} size="large" style={{backgroundColor: '#1B3C6A', color: '#fff'}} onClick={this.handleDetailsModal.bind(this)}>Requisitos previos</Button>
                  : null}
                  <Button icon={<SelectOutlined />} size="large" style={{backgroundColor: '#1B3C6A', color: '#fff'}} onClick={this.handleopenModalCarrers.bind(this)}>Asignar carrera</Button>
                </div>
              </div>

              <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
                {/* Content */}
                <div className="">
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.records}
                    renderItem={item => (
                      <List.Item key={`car-${item.id}`}>
                        <Row style={{width: '100%'}}>
                          <Col flex="1 1 200px">
                            <List.Item.Meta                      
                              title={<a href={`/acreditador/${this.state.acreditor.id}/carrera/${item.carrer?.id}/categorias`} style={{fontSize: '1.2em'}}>{item.carrer?.name}</a>}
                              description={`Criterios: ${item.criteria ? item.criteria : 0}`} 
                              style={{textAlign: 'left'}}
                            />
                          </Col>

                          <Col span={4}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                              <Tooltip placement="top" title={'Detalles'}>
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} href={`/acreditador/${this.state.acreditor.id}/carrera/${item.carrer?.id}/categorias`}><FileSearchOutlined /></Button>
                              </Tooltip>
                              <Tooltip placement="top" title={'Editar'}>
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.handleEditModal.bind(this, item)}><EditOutlined /></Button>
                              </Tooltip>
                              {/* <Tooltip placement="top" title={'Duplicar'}>
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.duplicateRecord.bind(this, item)}><CopyOutlined /></Button>
                              </Tooltip> */}
                              <Tooltip placement="bottom" title={'Borrar'}>
                                <Button shape="circle" danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteRecord.bind(this, item)}>
                                    <DeleteOutlined />
                                  </Popconfirm>
                                </Button>
                              </Tooltip>
                            </div>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(OrganoDetalles);
