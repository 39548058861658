import { PlusOutlined, FileSearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Layout, Input, Button, Modal, Breadcrumb, List, Tooltip, message, Popconfirm, Slider, Select, Row, Col } from 'antd';
import React, { Component } from 'react';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Content } = Layout;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class CriterioIndicadores extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    // State
    this.state = {
      searching: false, 
      redirect: false, 
      redirectUrl: '', 
      records: [], 
      carrers: [],
      loading: false, 
      openModal: false, 
      modalTitle: '', 
      selectedRecord: {}, 
      openModalInstrument: false,
      openModalQuestion: false, 
      modalTitleQuestion: '', 
      selectedQuestion: {}, 
      acreditor: {},
      carrer: {},
      category: {},
      criteria: {},
      areas: [],
      users: [],
      openModalCarrers: false
    };
  }

  componentDidMount() {
    const params = this.props.params;
    if ('acreditor' in params && 'carrer' in params) {
      this.getAcreditor(params.acreditor);
      this.getCarrer(params.carrer);
      this.getCategory(params.categoria);
      this.getCriteria(params.criterio);
      this.getAreas("");
      this.getUsers("");
      this.getRecords(params.criterio);
    }
  }

  getAcreditor(id) {
    const query = `{getAcreditor(id: ${id}){id,shortName}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el órgano acreditador.');
      }

      localStorage.setItem('acreditorId', resp.data.data.getAcreditor.id);
      this.setState({acreditor: resp.data.data.getAcreditor});
    });
  }

  getCarrer(id) {
    const query = `{getCarrer(id: ${id}){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la carrera.');
      }

      localStorage.setItem('carrerId', resp.data.data.getCarrer.id);
      this.setState({carrer: resp.data.data.getCarrer});
    });
  }

  getCategory(id) {
    const query = `{getCriteriaCategory(id: ${id}){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar la categoria.');
      }

      localStorage.setItem('categoryId', resp.data.data.getCriteriaCategory.id);
      this.setState({category: resp.data.data.getCriteriaCategory});
    });
  }

  getCriteria(id) {
    const query = `{getCriteria(id: ${id}){id,name,description}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al consultar el criterio.');
      }

      localStorage.setItem('criteriaId', resp.data.data.getCriteria.id);
      this.setState({criteria: resp.data.data.getCriteria});
    });
  }

  getAreas(search) {
    const query = `{listAreas(search: "${search}"){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al consultar las areas.');
      }

      this.setState({areas: resp.data.data.listAreas});
    });
  }

  getUsers(search) {
    const query = `{listUsers(search: "${search}"){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al consultar los usuarios.');
      }

      this.setState({users: resp.data.data.listUsers});
    });
  }

  getRecords(id) {
    const query = `{listIndicatorFromCriteria(id: ${id}){id,folio,question,status,maxVal,notes,user{id,name},users{id,name},area{id,name},areas{id,name}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        // console.log(resp.data);
        return message.warning('Error al consultar los indicadores.');
      }

      this.setState({records: resp.data.data.listIndicatorFromCriteria});
    });
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo indicador', selectedRecord: {}});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('id' in this.state.selectedRecord) {
      this.updateRecord(this.state.selectedRecord);
    } else {
      this.createRecord(this.state.selectedRecord);
    }
  }

  createRecord(record) {
    // console.log(record);
    const maxVal = record.maxVal ? record.maxVal : 0;
    
    // Add new record
    let query = `mutation{createIndicator(indicator: {criteriaId: ${this.state.criteria.id}, folio: "${record.folio}", question: "${record.question}", maxVal: ${maxVal}}){id}}`;

    // Body post
    const bodyRequest = {
      notes: record.notes
    }

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {data: bodyRequest}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al crear el registro. Llena todos los campos obligatorios.');
      }

      // Assign users
      if (record.users) {
        record.users.forEach(user => {
          if (user) {
            const ind = resp.data.data.createIndicator;
            let queryUser = `mutation{createIndicatorUser(indicatorId: ${ind.id}, userId: ${user}){id}}`;
            axios.post(`${Configuration.apiServer}/api/v1?query=${queryUser}`, {}, {headers: this.headers}).then(resp => {
              console.log(resp);
            });
            // console.log(queryUser);
          }
        });
      }

      // Assign areas
      if (record.areas) {
        record.areas.forEach(user => {
          if (user) {
            const ind = resp.data.data.createIndicator;
            let queryUser = `mutation{createIndicatorArea(indicatorId: ${ind.id}, areaId: ${user}){id}}`;
            axios.post(`${Configuration.apiServer}/api/v1?query=${queryUser}`, {}, {headers: this.headers}).then(resp => {
              console.log(resp);
            });
            // console.log(queryUser);
          }
        });
      }

      // Hide modal
      this.setState({openModal: false, modalTitle: '', });
      const instance = this;
      setTimeout(() => {
        instance.getRecords(this.state.criteria.id);
      }, 1500);
    });
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar indicador', selectedRecord: record});
  }

  updateRecord(record) {
    console.log(record);
    const selected = this.state.selectedRecord;
    let query = `mutation{updateIndicator(id: ${selected.id}, indicator: {criteriaId: ${this.state.criteria.id}, folio: "${record.folio}", question: "${record.question}", maxVal: ${record.maxVal}}){id}}`;

    // Body post
    const bodyRequest = {
      notes: record.notes
    }

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {data: bodyRequest}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      // Assign users
      if (record.users) {
        record.users.forEach(user => {
          if (user) {
            const ind = resp.data.data.createIndicator;
            let queryUser = `mutation{createIndicatorUser(indicatorId: ${selected.id}, userId: ${user}){id}}`;
            axios.post(`${Configuration.apiServer}/api/v1?query=${queryUser}`, {}, {headers: this.headers}).then(resp => {
              console.log(resp);
            });
            // console.log(queryUser);
          }
        });
      }

      // Assign users
      if (record.areas) {
        record.areas.forEach(user => {
          if (user) {
            let queryUser = `mutation{createIndicatorArea(indicatorId: ${selected.id}, areaId: ${user}){id}}`;
            axios.post(`${Configuration.apiServer}/api/v1?query=${queryUser}`, {}, {headers: this.headers}).then(resp => {
              console.log(resp);
            });
            // console.log(queryUser);
          }
        });
      }

      // Hide modal
      this.setState({openModal: false, selectedRecord: {}, modalTitle: '', loading: false});
      const instance = this;
      setTimeout(() => {
        instance.getRecords(this.state.criteria.id);
      }, 1500);
    });
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  deleteRecord(record) {
    const query = `mutation{deleteIndicator(id: ${record.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      this.getRecords(this.state.criteria.id);
    });
  }

  deleteUser(user, indicator) {
    const query = `mutation{deleteIndicatorUser(indicatorId: ${indicator.id}, userId: ${user.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      this.getRecords(this.state.criteria.id);
    });
  }

  deleteArea(area, indicator) {
    const query = `mutation{deleteIndicatorArea(indicatorId: ${indicator.id}, areaId: ${area.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data)
        return message.warning('Error al actualizar el registro. Llena todos los campos obligatorios.');
      }

      this.getRecords(this.state.criteria.id);
    });
  }

  statusColor(status) {
    switch(status) {
      case 'Incompleto':
        return '#FDC600';
      case 'Por revisar':
        return '#FDC600';
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#FB4D4F';
      case 'Autorizado':
        return '#52C51A';
      default:
        return '#FDC600';
    }
  }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false} 
          width={900} 
        >
          <div>
            <div className="ant-form-item">
              <div className="ant-row ant-form-item-row">
                <div className="ant-col ant-col-8 ant-form-item-label">
                  <label className="ant-form-item-required" title="Número">Número de indicador</label>
                </div>
                <div className="ant-col ant-col-16 ant-form-item-control">
                  <Input value={this.state.selectedRecord?.folio} onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, folio: val.target.value}})} />
                </div>
              </div>
            </div>

            <div className="ant-form-item">
              <div className="ant-row ant-form-item-row">
                <div className="ant-col ant-col-8 ant-form-item-label">
                  <label className="ant-form-item-required" title="Nombre">{this.state.acreditor?.shortName?.toLowerCase() === 'conaet' ? "Nombre del indicador" : "Pregunta"}</label>
                </div>
                <div className="ant-col ant-col-16 ant-form-item-control">
                  <Input value={this.state.selectedRecord?.question} onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, question: val.target.value}})} />
                </div>
              </div>
            </div>

            {this.state.acreditor?.shortName?.toLowerCase() !== 'conaet' ? 
              <div className="ant-form-item">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-col-8 ant-form-item-label">
                    <label className="ant-form-item-required" title="Valor maximo">Valor máximo</label>
                  </div>
                  <div className="ant-col ant-col-16 ant-form-item-control">
                    <Slider value={this.state.selectedRecord?.maxVal} onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, maxVal: val.target.value}})} max={20} min={1} disabled={false} />
                  </div>
                </div>
              </div>
            : null}

            <div className="ant-form-item">
              <div className="ant-row ant-form-item-row">
                <div className="ant-col ant-col-8 ant-form-item-label">
                  <label className="ant-form-item" style={{marginBottom: '0px'}} title="Asignar a usuario">Agregar usuarios</label>
                </div>
                <div className="ant-col ant-col-16 ant-form-item-control">
                  <Select
                    showSearch
                    mode='multiple'
                    placeholder="Elige a un usuario"
                    optionFilterProp="children"
                    onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, users: val}})}
                    onSearch={() => {}}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.users.map((item) => {
                      if (item && item?.id) {
                        return ({key: `${item.id}-opt-user`, value: item.id, label: item.name})
                      }
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="ant-form-item">
              <div className="ant-row ant-form-item-row">
                <div className="ant-col ant-col-8 ant-form-item-label">
                  <label className="ant-form-item" style={{marginBottom: '0px'}} title="Asignar a un área">Agregar áreas</label>
                </div>
                <div className="ant-col ant-col-16 ant-form-item-control">
                  <Select
                    showSearch
                    placeholder="Elige un área"
                    optionFilterProp="children"
                    mode='multiple'
                    onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, areas: val}})}
                    onSearch={() => {}}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.areas.map((item) => {
                      if (item && item?.id) {
                        return ({key: `${item.id}-opt-area`, value: item.id, label: item.name})
                      }
                    })}
                  />
                </div>
              </div>
            </div>
            
            <div className="ant-form-item" style={{marginBottom: 50}}>
              <div className="ant-row ant-form-item-row">
                <div className="ant-col ant-col-8 ant-form-item-label">
                  <label className="ant-form-item" style={{marginBottom: '0px'}} title="Descripción">Descripción</label>
                </div>
                <div className="ant-col ant-col-16 ant-form-item-control">
                  <ReactQuill modules={Configuration.editorModules} formats={Configuration.editorFormats} theme="snow" value={this.state.selectedRecord?.notes} onChange={(val) => this.setState({selectedRecord: {...this.state.selectedRecord, notes: val}})} style={{height: 200}} />
                </div>
              </div>
            </div>

            <br /> <br />

            <div className='ant-row ant-form-item-row' style={{justifyContent: 'center', marginTop: '10px'}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" onClick={this.handleSubmitModal.bind(this)}>Guardar</Button>
            </div>
          </div>
        </Modal>
        
        {/* Top menu */}
        <TopMenu />

        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div style={{padding: 24, textAlign: 'center'}}>
              <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Ir a</Breadcrumb.Item>
                <Breadcrumb.Item> <a href="/organos-acreditadores" style={{background: '#1890ff', color: 'white', padding: 5, marginTop: 5, marginBottom: 5}}>Organos acreditadores</a> </Breadcrumb.Item>
                <Breadcrumb.Item><a href={`/organo-acreditador/${this.state.acreditor.id}`}style={{background: '#1890ff', color: 'white', padding: 5, marginTop: 5, marginBottom: 5}} >Carreras de {this.state.acreditor.shortName}</a></Breadcrumb.Item>
                <Breadcrumb.Item><a href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.carrer.id}/categorias`} style={{background: '#1890ff', color: 'white', padding: 5, marginTop: 5, marginBottom: 5}}>Categorías de {this.state.carrer.name}</a></Breadcrumb.Item>
                <Breadcrumb.Item><a href={`/acreditador/${this.state.acreditor.id}/carrera/${this.state.carrer.id}/categorias/${this.state.category.id}/criterios`} style={{background: '#1890ff', color: 'white', padding: 5, marginTop: 5, marginBottom: 5}}>Criterios de {this.state.category.name}</a></Breadcrumb.Item>
              </Breadcrumb>

              <br /><br /><br />

              <div className='container-between' style={{marginBottom: 50, alignItems: 'center'}}>
                <div>
                  <h3 style={{marginBottom: 0, textAlign: 'left'}}>Indicadores</h3> 
                  <div style={{backgroundColor: '#f1f1f1', padding: 5, borderRadius: 5, maxWidth: '90%'}}>
                    {this.state.criteria?.description ? 
                    <div dangerouslySetInnerHTML={{ __html: this.state.criteria?.description }} />
                    :
                    <span>...</span>
                    }
                  </div>
                </div>
                
                <div>
                  <Button icon={<PlusOutlined />} size="large" style={{backgroundColor: '#1B3C6A', color: '#fff'}} onClick={this.handleCreateModal.bind(this)}>Nuevo indicador</Button>
                </div>
              </div>

              <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
                {/* Content */}
                <div className="">
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.records}
                    renderItem={item => (
                      <List.Item key={`car-${item.id}`} style={{width: '100%'}}>
                          <Row style={{width: '100%'}}>
                            <Col flex="1 1 200px">
                              <List.Item.Meta
                                title={
                                  <div style={{textAlign: 'left', paddingRight: '40px'}}>
                                    <div style={{backgroundColor: '#ddd', padding: '10px 5px', borderRadius: 5}}>
                                      <span style={{marginRight: '6px', fontWeight: 'bold'}}>{item.folio}</span>
                                      <span style={{color: '#1B3C6A', paddingRight: 30}}>
                                        <b>{item.question}</b>
                                      </span>
                                    </div>

                                    <div style={{backgroundColor: '#f1f1f1', padding: '10px 5px', borderRadius: 5}}>
                                      {item.user ? 
                                        <p style={{marginBottom: 10, fontWeight: 'bold'}}>
                                          <span style={{marginRight: 5}}>Creado por</span>
                                          <span>{item.user.name}</span>
                                        </p>
                                      : null}

                                      <div style={{marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-start'}}>
                                        <span style={{marginRight: 5}}>Asignado a</span>
                                        {item.users.map((u) => {
                                          return (<div key={`ind-user-${u.id}`} style={{border: '1px solid #ccc', borderRadius: 5, marginRight: 5, padding: 5}}>
                                            <span style={{marginRight: 10}}>{u.name} </span>
                                            <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteUser.bind(this, u, item)}>
                                              <DeleteOutlined style={{color: '#ff4d4f'}} />
                                            </Popconfirm>
                                          </div>);
                                        })}
                                      </div>

                                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-start'}}>
                                        <span style={{marginRight: 5}}>Áreas</span>
                                        {item.areas.map((u) => {
                                          return (<div key={`ind-area-${u.id}`} style={{border: '1px solid #ccc', borderRadius: 5, marginRight: 5, padding: 5}}>
                                            <span style={{marginRight: 10}}>{u.name} </span>
                                            <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteArea.bind(this, u, item)}>
                                              <DeleteOutlined style={{color: '#ff4d4f'}} />
                                            </Popconfirm>
                                          </div>);
                                        })}
                                      </div>
                                    </div>

                                    {item.notes ?
                                      <div style={{marginTop: 10, marginBottom: 50}} dangerouslySetInnerHTML={{ __html: item.notes }} />
                                    : null}
                                  </div>
                                }
                              />
                            </Col>

                            <Col span={4}>
                              {/* <div style={{padding: '5px 10px', border: '1px solid #777', borderRadius: 10, marginRight: 10, fontSize: 12, marginBottom: '5px'}}>Valor máximo: {item.maxVal}</div> */}
                              <div style={{borderWidth: 1, backgroundColor: this.statusColor(item.status), color: '#fff', padding: '5px 10px', borderRadius: 10, marginRight: 10, fontSize: 12}}>{item.status}</div>
                            </Col>

                            <Col span={4}>
                              <Tooltip placement="top" title={'Detalles'}>
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} href={`/indicador/${item.id}`}><FileSearchOutlined /></Button>
                              </Tooltip>
                              <Tooltip placement="top" title={'Editar'}>
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} onClick={this.handleEditModal.bind(this, item)}><EditOutlined /></Button>
                              </Tooltip>
                              <Tooltip placement="bottom" title={'Borrar'}>
                                <Button shape="circle" danger>
                                  <Popconfirm title="¿Deseas borrar el registro?" cancelText='Cancelar' onConfirm={this.deleteRecord.bind(this, item)}>
                                    <DeleteOutlined />
                                  </Popconfirm>
                                </Button>
                              </Tooltip>
                            </Col>
                          </Row>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(CriterioIndicadores);
