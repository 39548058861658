import { LogoutOutlined, BellOutlined, UserOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/logo_sm_ng.png';

const { Header } = Layout;

class TopMenu extends Component {
  render() {
    return (
      <Header style={{backgroundColor: '#FDC600'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontWeight: 'bold'}}>
          <div className="container-center" style={{ flexWrap: 'wrap', flexDirection: 'column'}}>
            <img src={Logo} height="48" alt="DCEA" style={{ margin: 0 }} />
            <h3 style={{textAling: 'center', color: '#233C60', fontSize: '0.8em', lineHeight: 1}}>Sistema de Evaluación y Acreditación</h3>
          </div>

          <div>
            <a href='/notificaciones' style={{color: '#212121', marginLeft: 20}}>
              <BellOutlined /> 
              <span style={{marginLeft: 5}}>Notificaciones</span>

              <div style={{position: 'absolute', backgroundColor: '#212121', width: 15, height: 15, borderRadius: 20, color: '#fff', marginTop: -50, marginLeft: 138, lineHeight: 1.5, textAlign: 'center', fontSize: 10}}>1</div>
            </a>

            <a href='/perfil' style={{color: '#212121', marginLeft: 40}}>
              <UserOutlined />
              <span style={{marginLeft: 5}}>Perfil</span>
            </a>

            <a href="/login" style={{color: '#212121', marginLeft: 40}}>
              <LogoutOutlined />
              <span style={{marginLeft: 5}}>Salir</span>
            </a>
          </div>
        </div>
      </Header>
    );
  }
}

export default TopMenu;