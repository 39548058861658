import { AppstoreOutlined, ExceptionOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { Component } from 'react';

const { Sider } = Layout;

class SideMenuProfesor extends Component {
  constructor(props) {
    super(props);

    this.state = {rol: ''};
  }

  componentDidMount() {
    const rol =  localStorage.getItem('rol');
    this.setState({rol: rol});
  }

  render() {
    return (
      <Sider style={{overflow: 'auto', minHeight: '90vh', left: 0, top: 0, bottom: 0, background: '#1B3C6A'}}>
        <ul style={{listStyle: 'none', marginTop: 20, padding: 0}}>
          <li style={{paddingLeft: '25%', paddingTop: 15, paddingBottom: 15}}>
            <a href='/mis-indicadores' style={{color: '#fff'}}>
              <AppstoreOutlined /> Mis indicadores
            </a>
          </li>
          {this.state.rol === 'Coordinador' ? 
          <li style={{paddingLeft: '25%', paddingTop: 15, paddingBottom: 15}}>
            <a href='/mi-area' style={{color: '#fff'}}>
              <ExceptionOutlined /> Área
            </a>
          </li>
          : null}
        </ul>
      </Sider>
    );
  }
}

export default SideMenuProfesor;