import { DeleteOutlined, EditOutlined, SearchOutlined, PlusOutlined, BellOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Modal, Form, Popconfirm, Tag, message } from 'antd';
import React, { Component } from 'react';
import axios from 'axios';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';

const { Meta } = Card;
const { Search } = Input;
const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();

class Roles extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: [], records: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}};
  }

  componentDidMount() {
    this.getRecords("");
  }

  getRecords(words) {
    const query = `{listRols{id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al obtener los registros');
      }

      console.log(resp.data.data.listRols);
      const response = resp.data.data.listRols;
      this.setState({records: response, searching: false});
    });
  }

  onSearch(words) {
    this.setState({searching: true});
    // Search record
    this.getRecords(words);
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    if (item.key != "2") {
      const route = Configuration.getRoute(item);
      this.setState({redirect: true, redirectUrl: route});
    }
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo rol'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    this.setState({loading: true});
    const query = `mutation{createRol(name: "${record.name}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        this.setState({loading: false});
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        this.setState({loading: false});
        return message.warning('Error al crear el registro');
      }

      const response = resp.data.data.createRol;
      //console.log(response);
      this.setState({openModal: false, modalTitle: '', loading: false, selectedRecord: {}});
      this.onSearch('');
    });
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar rol', selectedRecord: record});
  }

  updateRecord(record) {
    const selected = this.state.selectedRecord;
    
    this.setState({loading: true});
    const query = `mutation{updateRol(id: ${selected.id}, name: "${record.name}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        this.setState({loading: false});
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        this.setState({loading: false});
        return message.warning('Error al crear el registro');
      }

      const response = resp.data.data.updateRol;
      //console.log(response);
      this.setState({openModal: false, modalTitle: '', loading: false, selectedRecord: null, selectedRecord: {}});
      this.onSearch('');
    });
  }

  deleteRecord(record) {
    this.setState({loading: true});
    const query = `mutation{deleteRol(id: ${record.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        this.setState({loading: false});
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        this.setState({loading: false});
        return message.warning('Error al crear el registro');
      }

      const response = resp.data.data.deleteRol;
      //console.log(response);
      this.setState({openModal: false, modalTitle: '', loading: false, selectedRecord: {}});
      this.onSearch('');
    });
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  openDetails(record) {
    this.setState({redirect: true, redirectUrl: `/rol/${record.id}`});
  }



  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitModal.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            <Form.Item label="Nombre" name="name" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Top menu */}
        <TopMenu />

        {/* Content with two columns */}
        <Layout className="site-layout">
          <SideMenu />

          <Content style={{margin: '24px 16px 0', background: '#fff'}}>
            <div style={{padding: 24, textAlign: 'center'}}>

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px' }}>
                <h3>Roles </h3>

                <div className='container-end' style={{width: '50%'}}>
                  <Button type="primary" icon={<PlusOutlined />} size="large" onClick={this.handleCreateModal.bind(this)}>Agregar rol</Button>
                </div>
              </div>

              {/* Content */}
              <div className="container-around">
                {this.state.records.map((item, key) => {
                  return (
                    <Card 
                      key={`org-${key}`} 
                      style={{ width: '24%' }}
                      actions={[
                        // <SearchOutlined key="details" title='Ver detalles' onClick={this.openDetails.bind(this, item)} />,
                        <EditOutlined key="edit" title='Editar' onClick={this.handleEditModal.bind(this, item)} />,
                        <Popconfirm title="¿Estás seguro" cancelText='Cancelar' onConfirm={this.deleteRecord.bind(this, item)}>
                          <DeleteOutlined key="delete" title='Borrar' />
                        </Popconfirm>
                      ]}
                    >
                      <h2 style={{borderBottom: '1px dotted #ddd'}}>{item.name}</h2> 
                    </Card>
                  );
                })}
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Roles;
