import { PlusOutlined, SearchOutlined, DeleteOutlined, BellOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Modal, Form, Tabs, Avatar, Select, Tag, List } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/dcea_logo.png';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';

const { Header, Content, Sider } = Layout;
const { Meta } = Card;
const { Option } = Select;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class AreaDetalles extends Component {
  constructor(props) {
    super(props);

    const records = [
      {id: 1, name: 'Juan Perez', rol: 'Profesor', avatar: 'https://www.kindpng.com/picc/m/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png', indicators: 10, area: 'TI'},
    ];
    const allUsers = [
      {id: 1, name: 'Francisco Perez', rol: 'Profesor', avatar: 'https://www.kindpng.com/picc/m/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png', indicators: 0},
      {id: 2, name: 'Julieta Perez', rol: 'Asistente', avatar: 'https://www.kindpng.com/picc/m/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png', indicators: 0},
      {id: 3, name: 'Marcelo Perez', rol: 'Profesor', avatar: 'https://www.kindpng.com/picc/m/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png', indicators: 0},
    ];
    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: allUsers, records: records, targets: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}, openModalAssign: false, rol: '', itemsSideMenu: []};
  }

  componentDidMount() {
    //const params = this.props.params;
    //console.log(params);
    const rol =  localStorage.getItem('rol');
    if (rol) {
      let menu = [];
      if (rol == 'admin') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenu()});  
      }
      if (rol == 'coordinador') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenuCoor()});  
      }
      if (rol == 'profesor') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenuProf()});  
      }
    }
  }

  onSearch(words) {
    this.setState({searching: true});
    // Search record
    const list = this.state.allRecords.filter((el) => {
      if (el.name.match(words)) {
        return el;
      }
    });
    
    // Update list 
    this.setState({records: list, searching: false});
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    const route = Configuration.getRoute(item);
    this.setState({redirect: true, redirectUrl: route});
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo indicador'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    this.createRecord(data);
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = newRecords.length + 1;
    record.indicators = 0;
    newRecords.push(record);
    // Close modal    
    this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    let newRecords = this.state.records;
    newRecords.splice(filter, 1);
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  filterOption(inputValue, option) { 
    return option.name.indexOf(inputValue) > -1;
  }

  handleChange(newTargetKeys) {
    this.setState({targets: newTargetKeys});
  }

  goToTab(key) {
    const {id} = this.props.params;

    switch(key) {
      case 'ar-indicators':
        return this.setState({redirect: true, redirectUrl: `/area-indicadores/${id}`});
        case 'ar-users':
        return null;
    }
  }

  handleModalAssign() {
    this.setState({openModalAssign: true});
  }

  handleCancelModalAssign() {
    this.setState({openModalAssign: false}); 
  }

  assignUser(user) {
    // console.log(user);
    let newRecords = this.state.records;
    user.id = newRecords.length + 1;
    newRecords.push(user);
    this.setState({openModalAssign: false, records: newRecords});
  }


  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout hasSider>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitModal.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            <Form.Item label="Nombre completo" name="name" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Rol" name="rol" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Select
                showSearch
                placeholder="Elige un rol"
                optionFilterProp="children"
                onChange={() => {}}
                onSearch={() => {}}
                filterOption={() => {}}
              >
                <Option value="Coordinador">Coordinador</Option>
                <Option value="Asistente">Asistente</Option>
                <Option value="Profesor">Profesor</Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Modal for assign user */}
        <Modal
          open={this.state.openModalAssign}
          title={'Asignar usuario'}
          onOk={() => {}}
          onCancel={this.handleCancelModalAssign.bind(this)}
          footer={false}
        >
          <List
            itemLayout="horizontal"
            dataSource={this.state.allRecords}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title={item.name}
                  description={item.rol}
                />

                <Button type='primary' onClick={this.assignUser.bind(this, item)}>Asignar</Button>
              </List.Item>
            )}
          />
        </Modal>

        {/* Main layout and side menu */}
        <Sider style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, background: '#fff'}}>
          <div className="container-center" style={{ paddingBottom: '10px', flexWrap: 'wrap', flexDirection: 'column'}}>
            <img src={Logo} height="44" alt="DCEA" style={{ margin: '20px 0px 0px 0px' }} />
            <h3 style={{textAling: 'center', color: '#233C60', fontSize: '0.6em'}}>Sistema de Evaluación y Acreditación</h3>
          </div>

          <Menu theme="light" mode="inline" defaultSelectedKeys={['2']} items={this.state.itemsSideMenu} style={{marginTop: 20}} onClick={this.onClickSideMenu.bind(this)} />
        </Sider>

        <Layout className="site-layout" style={{marginLeft: 200}}>
          {/* Top menu */}
          <TopMenu />

          <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>
              <Tabs
                onChange={this.goToTab.bind(this)}
                type="card"
                items={[{label: `Ver usuarios`, key: 'ar-users', children: ''}, {label: `Ver indicadores`, key: 'ar-indicators', children: ''}]}
              />

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px', paddingTop: 10, borderTop: '1px solid #ddd' }}>
                <h3>Usuarios de área</h3>

                {this.state.rol == 'admin' ? 
                  <div className='container-end' style={{width: '50%'}}>
                    <Button type="primary" icon={<PlusOutlined />} size="large" onClick={this.handleModalAssign.bind(this)} style={{marginRight: 10}}>Asignar usuario</Button>
                    <Button type="primary" icon={<PlusOutlined />} size="large" onClick={this.handleCreateModal.bind(this)}>Crear usuario</Button>
                  </div>
                : null}
              </div>

              {/* Content */}
              <div className="container-around">
                {this.state.records.map((item) => {
                  return (
                    <Card
                      style={{width: 300}}
                      cover={<img src="https://c4.wallpaperflare.com/wallpaper/606/654/376/material-design-colors-wallpaper-preview.jpg" style={{height: 100}} />}
                      actions={[<a href={`/usuario-indicadores/${item.id}`}><SearchOutlined key="setting" /></a>, <DeleteOutlined key="ellipsis" onClick={this.deleteRecord.bind(this)} />]}
                      key={`user-${item.id}`}
                    >
                      <Meta
                        avatar={<Avatar src={item.avatar} />}
                        title={item.name}
                      />

                      <div style={{paddingTop: 10, borderTop: 'dotted 1px #ddd', marginTop: 25}}>
                        <p style={{textAlign: 'center', marginBottom: 5, color: '#777'}}>Área: <b>{item.area}</b></p>
                        <p style={{textAlign: 'center', marginTop: 0, marginBottom: 5, color: '#777'}}>Rol: <b>{item.rol}</b></p>
                        <p style={{textAlign: 'center', marginTop: 0, color: '#777'}}><Tag color='blue'>{item.indicators}</Tag> Indicadores</p>
                      </div>
                    </Card>
                  )
                })}
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(AreaDetalles);
